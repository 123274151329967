import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Image } from '../../../Image/Image';
import poster from '../../../../resources/images/meest/main-screen-2.png';
import './productMain.scss';
import title from '../../../../resources/images/meest/product-title.svg';
import AppStore from '../../../../resources/images/btn-download-apple-blue.svg';
import GooglePlay from '../../../../resources/images/btn-download-android-blue.svg';

const ProductMain = ({text}) => {
  return (
    <Container className="meest-product-main" fluid>
      <Row className="meest-main-row">
        <Col xs={12}>
          <img className="meest-main-title" src={title} alt="title" />
          <p className="meest-description" dangerouslySetInnerHTML={{__html: text.product.text}} />
        </Col>
        <Col className="mb-5 mt-4" xs={12}>
          <a href={text.product.app_store} target="_blank" rel="noopener noreferrer"><img className="meest-download-btn mr-3 mr-sm-4" src={AppStore} alt="appstore" /></a>
          <a href={text.product.google_play} target="_blank" rel="noopener noreferrer"><img className="meest-download-btn" src={GooglePlay} alt="googleplay" /></a>
        </Col>
        <Col xs={4} className="meest-main-col" align="center">
          <Image src={`meest/main-screens/${text.product.main_screens[0]}`} />
        </Col>
        <Col xs={4} className="meest-main-col" align="center">
          <video className="video" poster={poster} autoPlay loop muted>
            <source src={require(`../../../../resources/videos/${text.product.video}`)} type="video/mp4" />
          </video>
        </Col>
        <Col xs={4} className="meest-main-col" align="center">
          <Image src={`meest/main-screens/${text.product.main_screens[2]}`} />
        </Col>
      </Row>
    </Container>
  );
}

export default ProductMain;