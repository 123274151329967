import React from 'react';

export default ({active}) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.6667 5.83333C11.6667 4.91417 10.9192 4.16667 10.0001 4.16667C9.08091 4.16667 8.33341 4.91417 8.33341 5.83333C8.33341 6.7525 9.08091 7.5 10.0001 7.5C10.9192 7.5 11.6667 6.7525 11.6667 5.83333ZM13.3334 5.83333C13.3334 7.67167 11.8384 9.16667 10.0001 9.16667C8.16175 9.16667 6.66675 7.67167 6.66675 5.83333C6.66675 3.995 8.16175 2.5 10.0001 2.5C11.8384 2.5 13.3334 3.995 13.3334 5.83333ZM4.16675 16.6667C4.16675 13.45 6.78425 10.8333 10.0001 10.8333C13.2159 10.8333 15.8334 13.45 15.8334 16.6667C15.8334 17.1267 15.4609 17.5 15.0001 17.5C14.5392 17.5 14.1667 17.1267 14.1667 16.6667C14.1667 14.3692 12.2976 12.5 10.0001 12.5C7.70258 12.5 5.83341 14.3692 5.83341 16.6667C5.83341 17.1267 5.46091 17.5 5.00008 17.5C4.53925 17.5 4.16675 17.1267 4.16675 16.6667Z" fill={active ? "#0085FF" : "#B9C1CD"} />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="2" width="12" height="16">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.6667 5.83333C11.6667 4.91417 10.9192 4.16667 10.0001 4.16667C9.08091 4.16667 8.33341 4.91417 8.33341 5.83333C8.33341 6.7525 9.08091 7.5 10.0001 7.5C10.9192 7.5 11.6667 6.7525 11.6667 5.83333ZM13.3334 5.83333C13.3334 7.67167 11.8384 9.16667 10.0001 9.16667C8.16175 9.16667 6.66675 7.67167 6.66675 5.83333C6.66675 3.995 8.16175 2.5 10.0001 2.5C11.8384 2.5 13.3334 3.995 13.3334 5.83333ZM4.16675 16.6667C4.16675 13.45 6.78425 10.8333 10.0001 10.8333C13.2159 10.8333 15.8334 13.45 15.8334 16.6667C15.8334 17.1267 15.4609 17.5 15.0001 17.5C14.5392 17.5 14.1667 17.1267 14.1667 16.6667C14.1667 14.3692 12.2976 12.5 10.0001 12.5C7.70258 12.5 5.83341 14.3692 5.83341 16.6667C5.83341 17.1267 5.46091 17.5 5.00008 17.5C4.53925 17.5 4.16675 17.1267 4.16675 16.6667Z" fill="white"/>
    </mask>
  </svg>
  )
