import React, { useState } from 'react';
import text from '../../translation.json';
import { Row, Col } from 'reactstrap';
import './mobile.scss';

const Mobile = () => {
  const [ visible, setVisible ] = useState(
    [
      { id: 0, show: false },
      { id: 1, show: false },
      { id: 2, show: false },
      { id: 3, show: false },
      { id: 4, show: false },
      { id: 5, show: false }
    ]
  );
  const selected = (id) => visible.find(item => item.id === id);
  const handleClick = (id) => {
    setVisible(visible.map(item => {
      if(item.id === id) {
        item.show = !item.show;
      }
      return item;
    }));
  }
  return (
    <Row className="mobile">
    {
      text.development.columns.map(service => (
        <Col
          key={service.id}
          xs={12}
          className="column border-top-0">
          <Col
            className="mobile-column-layer"
            onClick={() => handleClick(service.id)}
            style={{ backgroundImage: `url(${require("../../resources/images/services/" + service.mobile)})`, opacity: selected(service.id) && selected(service.id).show ? 1 : 0 }}>
            <p className="mobile-column-text" dangerouslySetInnerHTML={{__html: service.def}} />
          </Col>
          <p className="mobile-column-title" dangerouslySetInnerHTML={{__html: service.text}} />
        </Col>
      ))
    }
    </Row>
  );
};

export default Mobile;