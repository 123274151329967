import React from 'react';
import text from '../../translation.json';
import { Container, Row, Col } from 'reactstrap';
import './mobile.scss';

const HowWorks = () => {
  return (
    <Container fluid className="mobile-howWorks">
      <Row className="justify-content-center">
        <Col xs={12} className="p-0">
          <input id={`collapsable1`} className="toggle" type="checkbox" />
          <label htmlFor={`collapsable1`} className="label-toggle border-top-0">
            <span className="mobile-label-text">{text.howWorks.first_text}</span>
            <span className="mobile-bg-text">01</span>
          </label>
          <Row className="collapse-content justify-content-center">
            <Col xs={10} className="collapse-inner mt-3">
              <p className="mobile-how-works-description">{text.howWorks.description1}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} className="p-0">
          <input id={`collapsable2`} className="toggle" type="checkbox" />
          <label htmlFor={`collapsable2`} className="label-toggle">
            <span className="mobile-label-text">{text.howWorks.second_text}</span>
            <span className="mobile-bg-text">02</span>
          </label>
          <Row className="collapse-content justify-content-center">
            <Col xs={10} className="collapse-inner mt-3">
              <p className="mobile-how-works-description">{text.howWorks.description2}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} className="p-0">
          <input id={`collapsable3`} className="toggle" type="checkbox" />
          <label htmlFor={`collapsable3`} className="label-toggle">
            <span className="mobile-bg-text">03</span>
            <span className="mobile-label-text">{text.howWorks.third_text}</span>
          </label>
          <Row className="collapse-content justify-content-center">
            <Col xs={10} className="collapse-inner mt-3">
              <p className="mobile-how-works-description">{text.howWorks.description3}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default HowWorks;