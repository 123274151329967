import React, { useState, useEffect } from 'react';
import Main from '../../components/Main/Main';
import Development from '../../components/Development/Development';
import Products from '../../components/Products/Products';
import Analytics from '../../components/Analytics/Analytics';
import HowWorks from '../../components/HowWorks/HowWorks';
import Svg from '../../resources/images/home-background.js';
import Svg1 from '../../resources/images/home-background-1.js';
import './home.scss';


const Home = () => {
  const [responFooter, setResponFooter] = useState(window.innerWidth);
  const mediaMatch = window.matchMedia('(max-width: 800px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const [opened1, setOpened1] = useState(false);
  const [opened2, setOpened2] = useState(false);
  const [opened3, setOpened3] = useState(false);

  const styles1 = {
    bottom: `-${17000/responFooter}rem`
  }
  const styles2 = {
    bottom: `-${21900/responFooter}rem`
  }
  const styles3 = {
    bottom: `-${25900/responFooter}rem`
  }
  const styles4 = {
    bottom: `-${27900/responFooter}rem`
  }
  useEffect(() => {
    window.addEventListener('resize', () => setResponFooter(window.innerWidth));
  }, []);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  });

  const resizedFooter = (footer) => footer < 2000 ? (footer < 1770 ? (footer < 1460 ? styles4 : styles3) : styles2) : styles1;

  return (
    <div className="home">
      <Main />
      <Development />
      <Analytics />
      <Products />
      <HowWorks 
        opened1={opened1}
        opened2={opened2}
        opened3={opened3}
        setOpened1={setOpened1}
        setOpened2={setOpened2}
        setOpened3={setOpened3} />
      {
        !matches ? ( responFooter < 1441 ?
          <Svg1
            styles={styles1}
            opened1={opened1}
            opened2={opened2}
            opened3={opened3}
            setOpened1={setOpened1}
            setOpened2={setOpened2}
            setOpened3={setOpened3} /> :
          <Svg
            styles={resizedFooter(responFooter)}
            opened1={opened1}
            opened2={opened2}
            opened3={opened3}
            setOpened1={setOpened1}
            setOpened2={setOpened2}
            setOpened3={setOpened3} />
        ) : null
        }
    </div>
  );
}

export default Home;