import React, { useState } from 'react';
import { InputGroup, Spinner, } from 'reactstrap';
import { uploadCV } from '../../../helpers/validationHelpers';
import uploadIcon from '../../../resources/icons/upload.svg';
import trashIcon from '../../../resources/icons/trash.svg';
import './uploadButton.scss';

export default ({ setFile }) => {
  const [show, setShow] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  return !show ?
    loading ? <Spinner type="grow" color="primary" /> : (
    <InputGroup className="upload-btn-container">
      <span className="btn-upload" onClick={null}>
        <img className="mr-1 upload-icon" src={uploadIcon} alt="upload-icon" />
        Upload your CV
      </span>
      <input
        type="file"
        accept="application/pdf"
        onChange={acceptedFiles => {
          setLoading(true);
          const file = uploadCV(acceptedFiles);
          file ? setError("") : setError("Please choose pdf with size less than 10Mb");
          setFile(file);
          setShow(file ? file.name : '');
          setLoading(false);
          }} />
      {error && <p className="error mt-3">{ error }</p>}
    </InputGroup>
    ) : (
      <p className="m-0">
        <span className="file-name">{show}</span>
        <img className="drop ml-2 mb-2" onClick={() => {setFile(null); setShow(false)}} src={trashIcon} alt="delete-icon" />
      </p>
    );
}
