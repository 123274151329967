import React, { Fragment, useState, useEffect } from 'react';
import CommitMain from '../../components/Projects/Commit/CommitMain/CommitMain';
import CommitDescription from '../../components/Projects/Commit/CommitDescription/CommitDescription';
import ScreensColumns from '../../components/Projects/Commit/ScreensColumns/ScreensColumns';
import CommitFlow from '../../components/Projects/Commit/CommitFlow/CommitFlow';
import CommitSlider from '../../components/Projects/Commit/CommitSlider/CommitSlider';
import CommitFeatures from '../../components/Projects/Commit/CommitFeatures/CommitFeatures';
import ProductCollaboration from '../../components/Projects/Commit/ProductCollaboration/ProductCollaboration';
import './commit.scss';

export default ({data}) => {
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  }, []);
  
  return (
    <div className="commit-wrapper">
      <CommitMain text={data} />
      <CommitDescription text={data} />
      {matches ? <CommitSlider text={data.onboarding} /> : <ScreensColumns text={data.onboarding} />}
      <div className="commit-wrapper-bg-1">
        <ScreensColumns text={data.registration} />
        <ScreensColumns text={data.community} />
      </div>
      <div className="commit-wrapper-bg-2">
        {matches ? null : <CommitFlow text={data.user_flow} />}
        <CommitSlider text={data.product} />
        <CommitFeatures text={data.features} />
      </div>
      <ProductCollaboration text={data} />
    </div>
  );
}
