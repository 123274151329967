import React from 'react';
import LazyLoad from 'react-lazyload';
import { Container, Row, Col } from 'reactstrap';
import { Image } from '../../../Image/Image';
import './screensColumns.scss';

export default ({text}) => {
  return (
    <Container className={`three-columns ${text.title.toLowerCase()}-block`} fluid>
        <h2 className="commit-block-title">{text.title}</h2>
        <Row className="justify-content-space-between align-items-center flex-row commit-block-row">
        {
          text.images.map((item, index) => (
            <Col key={index} className="three-columns-column">
              <LazyLoad once offset={800}>
                <Image
                  className={`${item.includes("phone.png") ? "phone" : ""}`}
                  src={`commit/${text.folder}/${item}`} alt="screen" />
              </LazyLoad>
            </Col>
          ))
        }
        </Row>        
    </Container>
    );
}
