import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Modal from '../Modal/Modal';
import './careersInfo.scss';

export default ({ text }) => {
  const [modal, setModal] = useState(false);
  const [position, setPosition] = useState('');
  const [response, setResponse] = useState('');
  const handleModal = (position) => {
    setModal(!modal);
    setPosition(position || '');
  }
  useEffect(() => {
    modal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
  }, [modal]);
  
  return (
    <Row className="careers-info">
      <Col className="p-0" sm={9} md={7} align="center">
        <h2 className="title mb-4">{text.title}</h2>
        <p className="description mb-5" dangerouslySetInnerHTML={{__html: text.text}} />
        <Row className="justify-content-center align-items-center">
          <Button onClick={() => {setResponse(''); handleModal("CV")}} className="careers-info-btn">{text.btn}</Button>
          <p className="email-text">{text.label}<a className="ml-2" href={`mailto:${text.email}`}>{text.email}</a></p>
        </Row>
      </Col>
      <Modal
        show={modal}
        toggle={handleModal}
        position={position}
        response={response}
        handleResponse={setResponse} />
    </Row>
  );
}
