import React, { useState } from 'react';
import { Row } from 'reactstrap';
import Main from '../../../components/Careers/Career/Main/Main';
import Description from '../../../components/Careers/Career/Description/Description';
import Contact from '../../../components/Careers/Career/Contact/Contact';
import Positions from '../../../components/Careers/Positions/Positions';
import getPageData from '../../../helpers/careerPage';
import { withRouter, Redirect } from 'react-router-dom';
import text from '../../../translation.json';
import './career.scss';

const Career = ({location}) => {
  const path = location.pathname.split("/")[2];
  const data = getPageData(path);
  const positions = {
    title: text.careers.career_positions_title,
    items: text.careers.items.items.filter(item => item.link !== path)
  }

  return data ? (
    <div className="career">
      <Main text={data.main} />
      <Row className="justify-content-between">
        <Description text={data.description} />
        <Contact
          text={data.contact}
          pos={data.main.title} />
        <Positions text={positions} block="career" />
      </Row>
    </div>
  ) : <Redirect to="/careers" />;
}
export default withRouter(Career);
