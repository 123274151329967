import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Row,
} from 'reactstrap';
import Contact from '../../components/Contact/Contact';
import Footer from '../../components/Footer/Footer';
import ContactUsFooter from '../../components/ContactUsFooter/ContactUsFooter';
import footerBg2 from '../../resources/images/footer-bg-2.svg';
import footerBg12 from '../../resources/images/footer-bg-1-2.svg';
import footerBg3 from '../../resources/images/footer-bg-3.svg';
import footerBg32 from '../../resources/images/footer-bg-3-2.svg';
import mFooterBg1 from '../../resources/images/mobile-footer-bg-1.svg';
import mFooterBg2 from '../../resources/images/mobile-footer-bg-2.svg';
import './wrapper.scss';

const Wrapper = (props) => {
  const mediaMatch = window.matchMedia('(max-width: 800px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const mediaMatch2 = window.matchMedia('(max-width: 1250px)');
  const [matches2, setMatches2] = useState(mediaMatch.matches);
  const url = props.history.location.pathname;
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    const handler2 = e => setMatches2(e.matches);
    mediaMatch.addListener(handler);
    mediaMatch2.addListener(handler2);
  });
  const footerBackgroundSwitch = () => {
    switch(url) {
      case '/': return matches ? <img className="home-footer-bg mobile" src={mFooterBg1} alt="footer-background" /> : null;
      case '/contact': return <img className="home-footer-bg contact" src={matches2 ? ( matches ? mFooterBg2 : footerBg32) : footerBg3} alt="footer-background" />;
      default: return <img className="home-footer-bg" src={matches2 ? ( matches ? mFooterBg1 : footerBg12) : footerBg2} alt="footer-background" />;
    }
  }

  return (
    <Container fluid className="wrapper">
      {props.children}
      {footerBackgroundSwitch()}
      {
        url === '/contact' ? <ContactUsFooter /> : (
          <Row className="footer">
            <Contact />
            {matches ? null : <Footer />}
          </Row>
        )
      }
    </Container>
  );
};

export default withRouter(Wrapper);