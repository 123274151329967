import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './productMain.scss';
import AppStore from '../../../../resources/images/btn-download-apple.svg';
import GooglePlay from '../../../../resources/images/btn-download-android.svg';
import mAppStore from '../../../../resources/images/btn-download-apple.svg';
import mGooglePlay from '../../../../resources/images/btn-download-android.svg';
import mainBg from '../../../../resources/images/privacy-bg-1.svg';
import mMainBg from '../../../../resources/images/mobile-product-main-bg.svg';

const ProductMain = ({text}) => {
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  }, []);
  return (
    <Container className="product-main" fluid>
      <img className="product-main-bg" src={matches ? mMainBg : mainBg} alt="background-dots" />
      <Row className="product-main-row">
        <Col xs={9} md={7} xl={5}>
          <h1 className="title">{text.product.title}</h1>
          <p className="description" dangerouslySetInnerHTML={{__html: text.product.text}} />
          <Row className="platforms">
            <a href={text.product.app_store} target="_blank" rel="noopener noreferrer"><img className="product-result-download-btn mr-3 mr-sm-4" src={matches ? mAppStore : AppStore} alt="appstore" /></a>
            <a href={text.product.google_play} target="_blank" rel="noopener noreferrer"><img className="product-result-download-btn" src={matches ? mGooglePlay : GooglePlay} alt="googleplay" /></a>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ProductMain;