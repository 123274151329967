import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import AppStore from '../../../../resources/images/btn-download-apple.svg';
import GooglePlay from '../../../../resources/images/btn-download-android.svg';
import './productResult.scss';

const productResult = ({text}) => (
  <Container className="product-result py-5" fluid>
    <Row className="margin product-result-row">
      <Col sm={10} lg={7}>
        <h3 className="product-result-title my-3">{text.product_result.title}</h3>
        <p className="product-result-description" dangerouslySetInnerHTML={{__html: text.product_result.text}}></p>
        <div className="product-result-row-btns">
          <a href={text.product.app_store} target="_blank" rel="noopener noreferrer"><img className="product-result-download-btn mb-4 mb-sm-0 mr-sm-4" src={AppStore} alt="appstore" /></a>
          <a href={text.product.google_play} target="_blank" rel="noopener noreferrer"><img className="product-result-download-btn" src={GooglePlay} alt="googleplay" /></a>
        </div>
      </Col>
    </Row>
  </Container>
);

export default productResult;