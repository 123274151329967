import React from 'react';
import { Container } from 'reactstrap';
import './productSlider.scss';
import Slider from "react-slick";
import LazyLoad from 'react-lazyload';
import { Image } from '../../../Image/Image';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default ({text, mode}) => {
  const settings = {
    centerMode: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    touchThreshold: 50,
    buttons: false,
    draggable: true,
    };

    return (
        <Container className={`meest-slider ${mode}`} fluid>
          <h2 className="meest-title">{text.title}</h2>
          <p className="meest-text">{text.raw_text}</p>
          <div className="meest-margin">
            <Slider {...settings}>
                {text.screens.map((slide,i) => (
                    <div key={i}>
                      <LazyLoad once offset={1000}>
                        <Image className="meest-slider-img" src={`meest/${text.folder}/${slide}`} alt={`slide ${i}`} />
                      </LazyLoad>
                    </div>
                ))}
            </Slider>
          </div>
        </Container>
    );
};
