import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Tools.scss';

export default ({text}) => (
  <Container fluid className="services-item-tools-container">
    <Row className="services-item-tools">
      <Col xs={12} className="services-item-tools-col">
        <h3 className="services-item-tools-title mb-5">{text.title}</h3>
      </Col>
      <Col sm={10} className="services-item-tools-col">
        <ul className="services-item-tools-list">
          {text.items.map((workType, index) => <li key={index}>{workType}</li>)}            
        </ul>
      </Col>
    </Row>
  </Container>
);
