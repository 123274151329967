import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Form from '../../Form/Form';
import recruiterPhoto from '../../../../resources/images/careers/recruiter.png';
import successIcon from '../../../../resources/icons/success.svg';
import dismissIcon from '../../../../resources/icons/bad.svg';
import data from '../../../../translation.json';
import './contact.scss';

const Contact = ({ text, pos }) => {
  const [position, setPosition] = useState('');
  const [response, setResponse] = useState('');
  const [modal, setModal] = useState(false);

  const handleModal = () => setModal(!modal);

  useEffect(() => {
    modal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
  }, [modal]);

  const formData = data.careers.form;

  useEffect(() => setPosition(pos), []);
  return (
    <Col className="career-contact-wrapper" lg={5}>
      <div>
        <h4 className="title mb-3 mb-sm-4">{text.title}</h4>
        <Row className="recruiter">
          <img className="recruiter-photo" src={recruiterPhoto} alt="recruiter-photo" />
          <Col className="recruiter-col">
            <h5 className="recruiter-name">{text.recruiter.name}</h5>
            <p className="mb-2">{text.recruiter.position}</p>
            <a href={`mailto:${text.recruiter.email}`}>{text.recruiter.email}</a>
            <p className="m-0">
              <a href={`skype:${text.recruiter.skype.link}?chat`} className="mr-1">{text.recruiter.skype.link}</a>
              {text.recruiter.skype.text}
            </p>
          </Col>
        </Row>
      </div>
      <Form
        handleModal={handleModal}
        text={formData}
        position={position}
        handleResponse={setResponse} />
        <p className="email-text">{text.label}<a className="ml-2" href={`mailto:${text.email}`}>{text.email}</a></p>
        <Modal isOpen={modal} toggle={handleModal} centered size="md" className="modal-wrapper">
          <ModalHeader toggle={handleModal}></ModalHeader>
          <ModalBody className="response row justify-content-center">
            <Col align="center" xs={12}>
              <img className="mb-4" src={response.indexOf('!') !== -1 ? successIcon : dismissIcon} alt="success-icon" />
            </Col>
            <Col align="center" xs={12}>
              <p className="center" dangerouslySetInnerHTML={{__html: response}} />
            </Col>
          </ModalBody>
        </Modal>
    </Col>
  );
};

export default Contact;