import React, { useState, useEffect } from 'react';
import text from '../../../../translation.json';
import { Container, Row, Col } from 'reactstrap';
import mainBg1 from '../../../../resources/images/services_page/services-bg-1.svg';
import mainMBg1 from '../../../../resources/images/services_page/services-mobile-bg-1.svg';
import './servicesMain.scss';

export default () => {
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  }, []);
  return (
    <Container className="servicesMain" fluid>
      <img className="services-main-bg-1" src={matches ? mainMBg1 : mainBg1 } alt="services-background" />
      <Row className="m-0 mt-3 mt-lg-5 mb-3 mb-lg-4">
        <Col className="p-0">
          <h1 className="services-title">{text.services.title}</h1>
        </Col>
      </Row>
      <Row className="m-0">
        <Col className="p-0" xs={10} md={6}>
          <p className="description" dangerouslySetInnerHTML={{__html: text.services.text}} />
        </Col>
      </Row>
    </Container>
  );
};
