import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import text from '../../translation';
import { Container, Row, Col, } from 'reactstrap';
import PortfolioLoading from '../../components/PortfolioLoading/PortfolioLoading';
import './works.scss';

const Works = (props) => {
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [type, setType] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  let filteredArray;
  if(type.length === 0) {
    filteredArray = text.works.items.filter(work => work.text_type.map(item => item));
  } else {
    filteredArray = text.works.items.filter(work => type.every(item => work.text_type.includes(item)));
  }
  
  const image = text.works.items.find(img => img.id === photoIndex);
  if(image && (image.link === "burn")) {
    props.history.push(`/projects/${image.link}`);
  }
  const loadImages = (limit, skip) => {
    setIsLoading(true);
    let counter = 0;
    filteredArray.slice(skip, limit).map((work) => {
      let images = [require("../../resources/images/portfolio/" + work.bg), require("../../resources/images/portfolio/" + work.logo), require("../../resources/images/portfolio/" + work.app), require("../../resources/images/portfolio/" + work.app.substr(0, work.app.length-4) + '_2screen' + work.app.substr(-4))];
      images.map(item => {
        let img = new Image();
        img.onload = () => {
          counter++;
          if ( counter === filteredArray.slice(skip, limit).length*4 ) setIsLoading(false);
        }
        img.src = item;
        if (img.complete) {
          img.onload();
        }
      });
      
      return true;
    });
  };
  const handleTypeChange = (e) => {
    if (type.includes(e.target.value)) {
      const removeType = type.filter(item => item !== e.target.value);
      setType(removeType);
    } else {
      const addType = type.concat(e.target.value);
      setType(addType);
    }
  }
  useEffect(() => {
    loadImages(limit, skip);
  }, [limit]);
  useEffect(() => {
    isOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
  }, [isOpen]);
  return (
    <Container className="portfolio" fluid>
      <Row className="my-2 margin">
        <h1 className="portfolio-title">{text.works.title}</h1>
      </Row>
      <Row className="mb-3 mb-lg-5 margin">
        <Col className="p-0">
          <ul className="ks-cboxtags">
            {text.works.work_types.map((workType, index) => (
              <li key={index}><input onChange={handleTypeChange} type="checkbox" id={index} key={index} value={workType} /><label className={type.includes(workType) ? "checked" : "unchecked"} htmlFor={index}>{workType}</label></li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row className="margin projects">
        {
          filteredArray.slice(0, limit).map((work, index) => {
            if (isLoading && index >= skip) {
              return (<div key={index} className="portfolio-column mb-4 loading"><PortfolioLoading/></div>); 
            } else {
              const logo1x = work.logo;
              const logo2x = work.logo.substr(0, work.logo.length-4) + '@2x' + work.logo.substr(-4);
              const logo3x = work.logo.substr(0, work.logo.length-4) + '@3x' + work.logo.substr(-4);
              const app = work.app;
              const app2screen = work.app.substr(0, work.app.length-4) + '_2screen' + work.app.substr(-4);
              return (
                <Col
                  key={index}
                  className="portfolio-column mb-4"
                  style={{backgroundImage: `url(${require("../../resources/images/portfolio/" + work.bg)})`}}
                  onClick={() => {
                    if (work.details) {
                      setIsOpen(true); 
                      setPhotoIndex(work.id);
                    } else {
                      props.history.push(`/projects/${work.link}`)
                    }                    
                  }}
                >
                    <div className="portfolio-work-wrapper">
                      <div>
                        <img className="portfolio-work-logo" 
                             src={require("../../resources/images/portfolio/" + logo1x)} 
                             srcSet={require("../../resources/images/portfolio/" + logo1x) + ' 1x,' +
                                     require("../../resources/images/portfolio/" + logo2x) + ' 2x,' +
                                     require("../../resources/images/portfolio/" + logo3x) + ' 3x'}
                             alt={work.name} />
                        <div className="portfolio-work-text" dangerouslySetInnerHTML={{ __html: work.text }}></div>
                      </div>
                      <div className="portfolio-work-types">
                        <ul>
                          {work.text_type.map((item, index) => <li key={index}>{item}</li>)}
                        </ul>
                      </div>
                    </div>
                    <div> 
                      <img className="portfolio-work-app desktop" src={require("../../resources/images/portfolio/" + app2screen)} alt={work.name} />
                      <img className="portfolio-work-app mobile" src={require("../../resources/images/portfolio/" + app)} alt={work.name} />
                    </div>
                </Col>
              )
            } 
          }           
        )}
      </Row>
      {
        filteredArray.length > limit ? (
          <Row className="my-sm-5 justify-content-center">
            <button
              onClick={() => { setSkip(limit); setLimit(limit + 10); setIsLoading(true); }}
              className="portfolio-products-load-more">
              {text.works.load_more}
            </button>
          </Row>
        ) : null
      }
      {image && isOpen && (
        <Lightbox
          // imagePadding={40}
          enableZoom={true}
          mainSrc={require(`../../resources/images/portfolio/${image.details}`)}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </Container>
  );
}

export default withRouter(Works);
