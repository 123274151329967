import React, { useState, useEffect } from 'react';
import text from '../../translation.json';
import { Container, Row, Col } from 'reactstrap';
import Mobile from './Mobile';
import './howWorks.scss';

const HowWorks = (props) => {
  const mediaMatch = window.matchMedia('(max-width: 800px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  });
  return (
    <Container fluid className="howWorks">
      <Row>
        <Col xs={12} lg={6} className="how-works-column">
          <h2 className="how-works-column-title">{text.howWorks.title}</h2>
          <p className="how-works-column-text">{text.howWorks.text}</p>
        </Col>
      </Row>
      {
        matches ? <Mobile /> : (
        <Row className="how-works-row2">
          <Col sm={7} className={`how-works-block1${props.opened1 ? " modal" : ""}`}>
            <p className="how-works-description">{text.howWorks.description1}</p>
          </Col>
          <Col sm={7} className={`how-works-block2${props.opened2 ? " modal" : ""}`}>
            <p className="how-works-description">{text.howWorks.description2}</p>
          </Col>
          <Col sm={7} className={`how-works-block3${props.opened3 ? " modal" : ""}`}>
            <p className="how-works-description">{text.howWorks.description3}</p>
          </Col>
        </Row>
        )
      }
    </Container>
  );
};

export default HowWorks;