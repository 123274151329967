import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import LazyLoad from 'react-lazyload';
import { withRouter } from 'react-router-dom';
import { Image } from '../../../Image/Image';
import ActiveContext from '../../../../helpers/activeContext';
import './Projects.scss';

const Projects = ({text, history}) => {
  const active = useContext(ActiveContext);
  const goToProject = (project) => {
    history.push(`/projects/${project}`);
  }
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  const getLogo = image => image.includes('.svg') ? (
    <img className="case-mobile-logo mr-2" src={require(`../../../../resources/images/services_page/projects/${image}`)} alt="project-logo" />
  ) : (
    <Image className="case-mobile-logo mr-2" src={`services/projects/${image}`} alt="project-phone" />
  );

  const getStepHeader = item => matches ? (
    (
      <Row className="align-items-center m-0 mb-3">
        <LazyLoad once offset={1000}>
          {getLogo(item.mLogo)}
        </LazyLoad>
        <h4 className="services-item-projects-step-title">{item.title}</h4>
      </Row>
    )
  ) : <h4 className="services-item-projects-step-title mb-3">{item.title}</h4>;

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  });
  return (
    <Row className="services-item-projects">
      <Col xs={12} className="services-item-projects-title-col" align="center">
        <h3 className="services-item-projects-title mb-4 pb-sm-5 mb-sm-5">{text.title}</h3>
      </Col>
      {
        text.cases.map((step, index) => (
          <Row key={index} className="services-item-projects-row">
            <img className="bg" src={require(`../../../../resources/images/services_page/project_bgs/${matches ? step.mBg : step.bg}`)} alt="project-bg" />
            <Col
              sm={12}
              md={6}
              className="services-item-projects-col">
                <LazyLoad once offset={1000}>
                  <Image className="case-image" src={`services/projects/${matches ? step.mPhone : step.phone}`} alt="project-phone" />
                </LazyLoad>
            </Col>
            <Col
              sm={12}
              md={4}
              key={index}
              className="services-item-projects-step">
                <div className="services-item-projects-step-content">
                  {getStepHeader(step)}
                  <p className="services-item-projects-step-text" dangerouslySetInnerHTML={{__html: step.text}} />
                  <button onClick={() => goToProject(step.link)} className="show-more" type="button">
                    <span className="project-more-info">
                      {step.view}
                    </span>
                  </button>
                </div>
            </Col>
          </Row>
        ))
      }
    </Row>
  );
}

export default withRouter(Projects);
