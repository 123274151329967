import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './productFeature.scss';
import { Image } from '../../../Image/Image';

const ProductFeatureBlock = ({text}) => {

  return (
    <Container className="meest-product-features" fluid>
      <Row className='meest-features-row'>
        {
          text.product_features.map((item, index) => (
            <Col xs={10} sm={6} lg={3} key={index} align="center">
              <Image className="meest-features-icon mb-5" src={`meest/features/${item.image}`} />
              <h4 className='meest-product-features-title'>{item.title}</h4>
              <p className='meest-product-features-text'>{item.text}</p>
            </Col>
          ))
        }
      </Row>
    </Container>
  );
};

export default ProductFeatureBlock;