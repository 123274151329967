import React from 'react';
import ProductMain from '../../components/Projects/Burn/ProductMain/ProductMain';
import ProductOverview from '../../components/Projects/Burn/ProductOverview/ProductOverview';
import ProductDescription from '../../components/Projects/Burn/ProductDescription/ProductDescription';
import ProductLayout from '../../components/Projects/Burn/ProductLayout/ProductLayout';
import ProductScreens from '../../components/Projects/Burn/ProductScreens/ProductScreens';
import ProductCollaboration from '../../components/Projects/Burn/ProductCollaboration/ProductCollaboration';
import ProductResult from '../../components/Projects/Burn/ProductResult/ProductResult';
import ProductFeatureBlock from '../../components/Projects/Burn/ProductFeatureBlock/ProductFeatureBlock';
import ProductSlider from '../../components/Projects/Burn/ProductSlider/ProductSlider';
import Compare from '../../components/Projects/Burn/Compare/Compare';

export default ({data}) => (
  <div className="burn-wrapper">
    <ProductMain text={data} />
    <ProductOverview text={data} />
    <ProductScreens text={data} />
    <ProductDescription text={data} />
    <ProductLayout text={data} />
    <Compare text={data} />
    <ProductFeatureBlock text={data} />
    <ProductSlider text={data.product_slides} />
    <ProductResult text={data} />
    <ProductCollaboration text={data} />
  </div>
);
