import React from 'react';
import { Row, Col } from 'reactstrap';
import './description.scss';

export default ({ text }) => (
  <Col lg={6} className="careers-description-wrapper">
    <div className="careers-description-block">
      <h4 className="title">{text.looking.title}</h4>
      <p className="description" dangerouslySetInnerHTML={{__html: text.looking.text}} />
    </div>
    <div className="careers-description-block">
      <h4 className="title">{text.requirements.title}</h4>
      <ul className="list">
        {
          text.requirements.items.map((item, index) => (
            <li key={index}>{item}</li>
          ))
        }
      </ul>
    </div>
    {
      text.perfect ? (
        <div className="careers-description-block">
          <h4 className="title">{text.perfect.title}</h4>
          <ul className="list">
            {
              text.perfect.items.map((item, index) => (
                <li key={index} dangerouslySetInnerHTML={{__html: item}} />
              ))
            }
          </ul>
        </div>
      ) : null
    }
    <div className="careers-description-block">
      <h4 className="title">{text.additional.title}</h4>
      <ul className="list">
        {
          text.additional.items.map((item, index) => (
            <li key={index}>{item}</li>
          ))
        }
      </ul>
    </div>
    {
      text.responsibilities ? (
        <div className="careers-description-block">
          <h4 className="title">{text.responsibilities.title}</h4>
          <ul className="list">
            {
              text.responsibilities.items.map((item, index) => (
                <li key={index} dangerouslySetInnerHTML={{__html: item}} />
              ))
            }
          </ul>
        </div>
      ) : null
    }
    <div className="careers-description-block">
      <h4 className="title">{text.offer.title}</h4>
      <ul className="list">
        {
          text.offer.items.map((item, index) => (
            <li
            
              key={index}
              dangerouslySetInnerHTML={{__html: item}} />
          ))
        }
      </ul>
    </div>
  </Col>
);