import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import { Container, Row, Col } from 'reactstrap';
import './productDescription.scss';

const ProductDescription = ({text}) => (
  <Container className="product-description" fluid>
    <Row className="product-description-row">
      <Col className="product-description-col-1 p-0">
        <h3 className="title mb-2 mb-md-4">{text.product_description.column1.title}</h3>
        <p className="description"dangerouslySetInnerHTML={{__html: text.product_description.column1.text}} />
      </Col>
      <Col className="product-description-col-2 p-0">
        <h3 className="title mb-2 mb-md-4">{text.product_description.column2.title}</h3>
        <p className="description" dangerouslySetInnerHTML={{__html: text.product_description.column2.text}} />
      </Col>
    </Row>
  </Container>
);

export default ProductDescription;