import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { Image } from '../../../Image/Image';
import ActiveContext from '../../../../helpers/activeContext';
import './Stages.scss';

export default ({text}) => {
  const active = useContext(ActiveContext);
  const arrowDown = (active) => active ? (
    <div className="cycle-arrow-down">
      <div className="cycle-arrow-wrapper">
        <span className="arrow-down left"></span>
        <span className="line-down"></span>
        <span className="arrow-down right"></span>
      </div>
    </div>
  ) : null;
  return (
    <Row className="services-item-stages">
      <Col xs={12}>
        <h3 className="services-item-stages-title mb-5">{text.title}</h3>
      </Col>
      <Col>
        <Row className="services-item-stages-row mt-4" >
        {
          text.items.map((service, index) => (
            <Col
              className="services-item-stages-stage-col mb-5"
              align="center"
              key={index}>
              <Image src={`services/${active}/icons/${service.icon}`} alt="project-feature" />
              <p dangerouslySetInnerHTML={{__html: service.text}} />
              <div className="cycle-arrow">
                <div className="cycle-arrow-wrapper">
                  <span className="arrow left"></span>
                  <span className="line"></span>
                  <span className="arrow right"></span>
                </div>
              </div>
              {arrowDown(service.arrow)}
            </Col>
          ))
        }
        </Row>
      </Col>
    </Row>
  );
}
