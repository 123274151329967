import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import title from '../../../../resources/images/commit/commit-title.svg';
import mTitle from '../../../../resources/images/commit/mobile-commit-title.svg';
import phone from '../../../../resources/images/commit/commit-main-phone.png';
import mPhone from '../../../../resources/images/commit/mobile-commit-main-phone.png';
import './commitMain.scss';

export default ({text}) => {
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    if(matches) {
      const imgMTitle = new Image();
      const imgMPhone = new Image();
      imgMTitle.src = mTitle;
      imgMPhone.src = mPhone;
      imgMTitle.onload = () => setLoad(false);
    } else {
      const imgTitle = new Image();
      const imgPhone = new Image();
      imgTitle.src = title;
      imgPhone.src = phone;
      imgTitle.onload = () => setLoad(false);
    }
  }, []);
  return load ? null : (
    <Container className="commit-main" fluid>
      <Row className="commit-main-row">
        <Col className="commit-main-col">
          <img className="commit-main-title" src={matches ? mTitle : title} alt="title" />
          <p className="commit-main-text">{text.main.text}</p>
          <img className="commit-main-phone" src={matches ? mPhone : phone} />
          <p className="commit-main-description" dangerouslySetInnerHTML={{__html: text.main.description}} />
        </Col>
      </Row>
    </Container>
  );
}
