import React from 'react';
import text from '../../translation.json';
import { Container, Row, Col } from 'reactstrap';
import './privacyPolicyMain.scss';
import mainBg1 from '../../resources/images/privacy-bg-1.svg'
import mainBg2 from '../../resources/images/privacy-bg-2.svg'

export default () => (
  <Container className="privacy-policy" fluid>
    <img className="careers-main-bg-1" src={mainBg1} alt="privacy-background" />
    <img className="careers-main-bg-2" src={mainBg2} alt="privacy-background" />
    <Row className="m-0 mt-3 mt-lg-5 mb-3 mb-lg-4">
      <Col className="p-0">
        <h1 className="careers-title">{text.privacyPolicy.title}</h1>
      </Col>
    </Row>
    <Row className="m-0">
      <Col className="p-0" xs={10} md={6}>
        <div className="description" dangerouslySetInnerHTML={{__html: text.privacyPolicy.text}} />
      </Col>
    </Row>
  </Container>
);
