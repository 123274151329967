import React, { useState, Fragment } from 'react';
import FormError from '../FormError/FormError';
import { withRouter } from 'react-router-dom';
import { checkEmailPattern, checkNamePattern, checkLength } from '../../helpers/validationHelpers';
import approvedIcon from '../../resources/icons/approved.svg';
import dismissedIcon from '../../resources/icons/dismissed.png';
import loadingIcon from '../../resources/icons/loading.svg';
import {
  Col,
  Button,
  Form,
  InputGroup,
  Input
} from 'reactstrap';
import text from '../../translation.json';


const Forma = (props) => {
  const [name , setName] = useState('');
  const [email , setEmail] = useState('');
  const [message , setMessage] = useState('');
  const [invalid , setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const { display, history } = props;

  const goToContact = () => history.push(`/contact`);

  const getBtnText = () => {
    switch (history.location.pathname) {
      case "/careers": return text.contact.btn1;
      case "/projects/burn": return text.contact.btn2;
      case "/projects/shrink": return text.contact.btn2;
    
      default: return text.contact.btn;
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    if (checkNamePattern(name) && message && checkEmailPattern(email)) {
      setLoading(true);
      const data = {
        'email': email,
        'name': name,
        'message': message
      };
      fetch(`${window.location.protocol}//${window.location.host}/api/v1/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(result => {
          setLoading(false);
          setResponse(result.data);
        })
        .catch(error => {
          setLoading(false);
          console.log("error");
          setResponse("error");
        })
    } else {
      event.stopPropagation();
      setInvalid(true);
    }
  };

  const handleChange = (value, setter) => {
    setResponse('');
    setter(value);
  }
  return (
    <Form className="row contact-form">
      {
        display ? (
          <Fragment>
            <Col sm={6} className="contact-form-margin mb-5 mb-sm-0">
              <InputGroup className="contact-form-group">
                <Input value={name} invalid={invalid && !checkNamePattern(name)} onChange={({ target: { value } }) => handleChange(value, setName)} className="contact-form-input" type="name" name="name" id="exampleName" placeholder={text.contact.name_placeholder} required />
              </InputGroup>
              {invalid && !checkNamePattern(name) ? <FormError content="name" /> : null}
            </Col>
            <Col sm={6} className="contact-form-margin mb-5 mb-sm-0">
              <InputGroup className="contact-form-group">
                <Input value={email} invalid={invalid && !checkEmailPattern(email)} onChange={({ target: { value } }) => handleChange(value, setEmail)} className="contact-form-input" type="email" name="email" id="exampleEmail" placeholder={text.contact.email_placeholder} required />
              </InputGroup>
              {invalid && !checkEmailPattern(email) ? <FormError content="email" /> : null}
            </Col>
            <Col sm={12} className="mb-3 my-sm-5 contact-form-margin">
              <InputGroup className="contact-form-group">
                <Input value={message} invalid={invalid && !message  && !checkLength(message)} onChange={({ target: { value } }) => handleChange(value, setMessage)} className="contact-form-input message" type="text" name="text" id="exampleText" placeholder={text.contact.message_placeholder} />
              </InputGroup>
              {invalid && !checkLength(message) ? <FormError content="message" /> : null}
              {response ? <p className={response === "error" ? "send-dismissed" : "send-approved"}>{response === "error" ? text.contact.dismissed : text.contact.approved}</p> : null}
            </Col>
          </Fragment>
      ) : null
      }
      <Col className="column-contact-btn mt-3 mt-sm-0">
        <Button disabled={response === "error"} onClick={display ? handleSubmit : goToContact} className="contact-btn" color="primary">{loading ? <img className="loading-icon" src={loadingIcon} alt="loading-icon" /> : response ? <img className="responseIcon" src={response === "error" ? dismissedIcon : approvedIcon} alt="error-icon" /> : getBtnText()}</Button>
      </Col>
    </Form>
  )
};

export default withRouter(Forma);