import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import mainBg from '../../../../resources/images/services_page/ios/explore/services-item-explore-bg.svg';
import mMainBg from '../../../../resources/images/services_page/ios/explore/mobile-services-item-explore-bg.svg';
import './Explore.scss';
import { withRouter } from 'react-router';

const Explore = ({text, history}) => {
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  const goToPortfolio = () => history.push("/works");

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  });
  return (
    <Container fluid className="services-item-explore-container">
      <img className="services-item-explore-bg" src={matches ? mMainBg : mainBg} alt="explore-bg" />
      <Row className="services-item-explore">
        <Col className="p-0" sm={8} md={6} lg={5} align="center">
          <h3 className="services-item-explore-title mb-4">{text.title}</h3>
          <p className="services-item-explore-text">{text.text}</p>
          <button onClick={goToPortfolio} className="services-item-explore-btn">{text.btn}</button>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Explore);
