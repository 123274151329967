import React from 'react';
import Main from '../../../components/Services/Shared/Main/Main';
import Stages from '../../../components/Services/Shared/Stages/Stages';
import Approach from '../../../components/Services/Shared/Approach/Approach';
// import Projects from '../../../components/Services/Shared/Projects/Projects';
import Explore from '../../../components/Services/Shared/Explore/Explore';
import ServicesBlocks from '../../../components/Services/Main/ServicesBlocks/ServicesBlocks';
import Serapator from '../../../components/Serapator/Serapator';
import { ActiveProvider } from '../../../helpers/activeContext';

export default ({data}) => (
  <ActiveProvider value="game-dev">
    <Main text={data.main} />
    <Stages text={data.support} />
    <Approach text={data.approach} />
    {/* <Projects text={data.projects} /> */}
    <Explore text={data.explore} />
    <ServicesBlocks />
    <Serapator />
  </ActiveProvider>
);
