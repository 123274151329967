import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Parallax from '../../../Parallax/Parallax';
import { Image } from '../../../Image/Image';
import './productOverview.scss';

const ProductOverview = ({text}) => {
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  }, []);

  return (
    <Container className="product-overview" fluid>
      <Row className="product-overview-row margin">
        <Col className="product-overview-column" sm={6}>
          <h3 className="product-overview-title">{text.product.client.title}</h3>
          <Row className="m-0">
            <Parallax>
              <Image src={`meest/logo/${text.product.logo}`} alt="product-logo" />
            </Parallax>
            <Col className="ml-3 p-0">
              <h5 className="product-overview-name">{text.product.client.name}</h5>
              <p className="product-overview-text">{text.product.client.description}</p>
            </Col>
          </Row>
        </Col>
        {
          !matches ? (
            <Col className="product-overview-column" sm={5}>
              <h3 className="product-overview-title">{text.product.overview.title}</h3>
              {
                <ul className="list mb-5 mb-sm-0">
                  {
                    text.product.overview.description.map((item, index) => (
                      <li key={index} dangerouslySetInnerHTML={{__html: item}} />
                    ))
                  }
                </ul>
              }
            </Col>
          ) : null
        }
      </Row>
      <Row className="product-overview-row align-items-center margin">
        <Col className="product-overview-column" sm={6}>
          <Row className="product-overview-row2">
            {
              text.product.services.icons.map((service, index) => (
                <Row
                  className="product-feature-row"
                  align="center"
                  key={index}>
                  <Col>
                    <Image
                      className="product-feature"
                      src={`meest/services/${service.image}`}
                      alt="feature" />
                  </Col>
                  <Col className="product-feature-col-2">
                    <p className="product-feature-caption">{service.text}</p>
                  </Col>
                </Row>
              ))
            }
          </Row>
        </Col>
        <Col className="product-overview-column mt-5" sm={5}>
          <h3 className="product-overview-title">{text.product.services.title}</h3>
          <p className="product-main-row2-services-description">{text.product.services.name}</p>
        </Col>
      </Row>
    </Container>
  );
  }

export default ProductOverview;