import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import text from '../../translation';
import Form from '../../components/Form/Form';
import bg1 from '../../resources/images/contactUs-bg-1.svg';
import bg2 from '../../resources/images/contactUs-bg-2.svg';
import mBg2 from '../../resources/images/mobile-contactUs-bg-2.svg';
import './contactUs.scss';

const ContactUs = () => {
  const mediaMatch = window.matchMedia('(max-width: 800px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  });
  return (
    <Container className="contactUs-wrapper" fluid>
      {matches ? null : <img className="contactUs-bg-1" src={bg1} alt="contact-background" />}
      {matches ? <img className="mobile-contactUs-bg" src={mBg2} alt="mobile-background" /> : <img className="contactUs-bg" src={bg2} alt="contact-background" />}
      <Row className="contactUs-margin">
        <Col sm={12} lg={8} className="p-0">
          <h2 className="contactUs-title">{text.contactUs.title}</h2>
          <p className="contactUs-text" dangerouslySetInnerHTML={{__html: text.contactUs.text}} />
          <Form display={true} />
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;