import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './careersMain.scss';
import { Image } from '../../Image/Image';
import LazyLoad from 'react-lazyload';
import mainBg from '../../../resources/images/careers-main-bg.svg';
import mMainBg from '../../../resources/images/mobile-careers-main-bg.svg';

const CareersMain = ({ text }) => {
  const mediaMatch = window.matchMedia('(max-width: 650px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  });

  return (
    <Container className="careers-main" fluid>
      <img className="carrers-main-bg" src={matches ? mMainBg : mainBg} alt="carrers-main-bg" />
      <Row className="careers-row">
        <Col className="careers-main-col-1" xs={11} lg={6}>
          <h1 className="title mb-4">{text.title}</h1>
          <p className="description" dangerouslySetInnerHTML={{__html: text.text}} />
        </Col>
        <Col className="careers-main-col-2" sm={6} align="right">
          <LazyLoad offset={1000}>
            <Image className="careers-main-image" src={`careers/main/${text.image}`} alt="careers-main" />
          </LazyLoad>
        </Col>
      </Row>
    </Container>
  );
}

export default CareersMain;