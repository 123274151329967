import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import LazyLoad from 'react-lazyload';
import { Image } from '../../../Image/Image';
import './productScreens.scss';

export default ({text}) => {

  const mediaMatch = window.matchMedia('(max-width: 700px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  });

  return (
    <div className="meest-screens-wrapper">
      <Container className="meest-screens-container" fluid>
        <Row className="meest-screens-row">
          <Col xs={12}>
            <h2 className="meest-screens-title">{text.title}</h2>
            <p className="meest-screens-text" dangerouslySetInnerHTML={{__html: matches ? text.raw_text : text.text}} />
          </Col>
        </Row>
      </Container>
      <div className="meest-screens-images-wrapper">
        <Row className="meest-screens-images">
          {
            text.screens.map((column, index) => (
              <Col xs={2} key={index}>
                {
                  column.map((screen, index) => (
                    <LazyLoad once offset={1000}>
                      <Image key={index} src={`meest/screens/${screen}`} alt="screen" />
                    </LazyLoad>
                  ))
                }
              </Col>
            ))
          }
        </Row>
      </div>
    </div>
  );
}