import React from 'react';
import './contactUsFooter.scss';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import text from '../../translation.json';
// import facebookLogo from '../../resources/icons/facebook.svg';
// import instagramLogo from '../../resources/icons/instagram.svg';
// import ballLogo from '../../resources/icons/ball.svg';
// import beLogo from '../../resources/icons/be.svg';
// import mediumLogo from '../../resources/icons/medium.svg';

const Contact = () => {
  return (
    <Container className="contactUsFooter-wrapper p-0" fluid>
      <Row className="contactUsFooter-margin">
        <Col xs={10} lg={4} className="contactUsFooter-column">
          <h2 className="contactUsFooter-title">{text.contactUs.address.title}</h2>
          <p className="contactUsFooter-text">{text.contactUs.address.text}</p>
        </Col>
        <Col xs={10} lg={7} className="contactUsFooter-column">
          <h2 className="contactUsFooter-title">{text.contactUs.office.title}</h2>
          <p className="contactUsFooter-text">{text.contactUs.office.text}</p>
        </Col>
        <Col className="contactUsFooter-column-navbar p-0">
          <ul className="contactUsFooter-socials-list">
            {/*<li><a href="https://www.facebook.com/perpetio" target="_blank" rel="noopener noreferrer"><img src={facebookLogo} alt="facebook-logo" /></a></li>*/}
            {/*<li><a href="https://www.instagram.com/perpetio" target="_blank" rel="noopener noreferrer"><img src={instagramLogo} alt="instagram-logo" /></a></li>*/}
            {/*<li><a href="https://dribbble.com/perpetio" target="_blank" rel="noopener noreferrer"><img src={ballLogo} alt="ball-logo" /></a></li>*/}
            {/*<li><a href="https://www.behance.net/perpetio_design" target="_blank" rel="noopener noreferrer"><img src={beLogo} alt="be-logo" /></a></li>*/}
            {/*<li><a href="https://medium.com/perpetio" target="_blank" rel="noopener noreferrer"><img src={mediumLogo} alt="medium-logo" /></a></li>*/}
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Contact);