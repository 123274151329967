import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './productMain.scss';
import { Image } from '../../../Image/Image';

const ProductMain = ({text}) => {
  return (
    <Container className="product-main-shrink" fluid style={{
      background: `url(/shrink/${text.product.bg}) no-repeat`
    }}>
      <Row className="product-main-row-shrink">
        <h1 className="product-main-title-shrink mobile">{text.product.title}</h1>
      </Row>
      <div className="mobile-wrapper" style={{
          background: `url(/shrink/${text.product.bg}) no-repeat`
        }}>
        <Row className="product-main-row-shrink">
          <Col xs={6} md={8} xl={8}>
            <h1 className="product-main-title-shrink desktop">{text.product.title}</h1>
          </Col>
          <Col xs={6} md={4} xl={4}>
            <Image className="product-main-img-shrink" src={`shrink/${text.product.image}`} />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default ProductMain;