import React from 'react';
import { Row, Col } from 'reactstrap';
import './Approach.scss';

export default ({text}) => (
  <Row className="services-item-approach">
    <Col xs={12} align="center">
      <h3 className="services-item-approach-title mb-5 pb-sm-5">{text.title}</h3>
    </Col>
    <Col sm={10} md={9} className="ml-md-4 ml-lg-5 services-item-approach-steps-col">
      <Row className="services-item-approach-steps">
        {
          text.steps.map((step, index) => (
            <Col
              sm={6}
              key={index}
              className={`services-item-approach-step ${text.item}`}>
              <h4 className="services-item-approach-step-title mb-3 pb-3">{step.title}</h4>
              <p className="services-item-approach-step-text">{step.text}</p>
            </Col>
          ))
        }
      </Row>
    </Col>
  </Row>
);
