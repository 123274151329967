import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Image } from '../../../../Image/Image';
import './mobile.scss';
import title from '../../../../../resources/images/meest/product-title.svg';
import AppStore from '../../../../../resources/images/btn-download-apple-blue.svg';
import GooglePlay from '../../../../../resources/images/btn-download-android-blue.svg';

const ProductMain = ({text}) => {
  return (
    <Container className="meest-product-main-mobile" fluid>
      <Row className="meest-main-row-mobile">
        <Col className="p-0" xs={12}>
          <img className="meest-main-title-mobile" src={title} alt="title" />
          <p className="meest-description-mobile" dangerouslySetInnerHTML={{__html: text.product.text}} />
        </Col>
        <Row className="mb-5 mt-3">
          <Col xs={6}>
            <a href={text.product.app_store} target="_blank" rel="noopener noreferrer"><img className="meest-download-btn mr-3 mr-sm-4" src={AppStore} alt="appstore" /></a>
            </Col>
          <Col xs={6}>
            <a href={text.product.google_play} target="_blank" rel="noopener noreferrer"><img className="meest-download-btn" src={GooglePlay} alt="googleplay" /></a>
          </Col>
        </Row>
        <Col className="p-0" xs={12} align="center">
          <Image className="meest-main-img" src={`meest/main-screens/${text.product.main_screens[1]}`} />
        </Col>
        <Col className="meest-main-col-mobile" align="center">
          <Image className="meest-main-img" src={`meest/main-screens/${text.product.main_screens[0]}`} />
        </Col>
        <Col className="meest-main-col-mobile" align="center">
          <Image className="meest-main-img" src={`meest/main-screens/${text.product.main_screens[2]}`} />
        </Col>
      </Row>
    </Container>
  );
}

export default ProductMain;