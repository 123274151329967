import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import mBg from '../../../../resources/images/careers/mobile-career-bg.svg';
import './main.scss';

export default ({ text }) => (
  <div className="career-main-wrapper">
    <ul className="career-main-nav">
      {
        text.crumbs.map((item, index) => (
          <li key={index}><Link to={item.link}>{item.name}</Link></li>
        ))
      }
    </ul>
    <Row className="m-0 mt-3 mt-sm-5">
      <img className="careers-mobile-bg" src={mBg} alt="background" />
      <h1 className="career-main-title mb-5">{text.title}</h1>
      <Col xs={12} className="d-flex flex-wrap p-0">
        {
          text.description.map((item, index) => (
            <div key={index} className="position-description mr-4">
              <img className="mr-2" src={require(`../../../../resources/icons/${item.icon}`)} alt="icon" />
              <p className="m-0">{item.text}</p>
            </div>
          ))
        }
      </Col>
    </Row>
  </div>
);