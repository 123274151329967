import React from 'react';

export const Chevron = () => (
  <svg className="positions-chevron-icon" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path className="positions-chevron-icon-color" fillRule="evenodd" clipRule="evenodd" d="M16.9997 21.9584C16.637 21.9584 16.2744 21.8195 15.9981 21.5433L10.3315 15.8766C9.77754 15.3227 9.77754 14.4274 10.3315 13.8734C10.8854 13.3195 11.7807 13.3195 12.3346 13.8734L17.0167 18.5555L21.6818 14.0505C22.247 13.5079 23.141 13.5235 23.685 14.0859C24.229 14.6484 24.2134 15.5465 23.651 16.0891L17.9843 21.5603C17.708 21.8266 17.3539 21.9584 16.9997 21.9584Z" fill="#231F20"/>
  <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="9" y="13" width="16" height="9">
  <path fillRule="evenodd" clipRule="evenodd" d="M16.9997 21.9584C16.637 21.9584 16.2744 21.8195 15.9981 21.5433L10.3315 15.8766C9.77754 15.3227 9.77754 14.4274 10.3315 13.8734C10.8854 13.3195 11.7807 13.3195 12.3346 13.8734L17.0167 18.5555L21.6818 14.0505C22.247 13.5079 23.141 13.5235 23.685 14.0859C24.229 14.6484 24.2134 15.5465 23.651 16.0891L17.9843 21.5603C17.708 21.8266 17.3539 21.9584 16.9997 21.9584Z" fill="white"/>
  </mask>
  </svg>
);
