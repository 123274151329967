import React from 'react';
import './contact.scss';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import text from '../../translation.json';
import Form from '../../components/Form/Form';
import facebookLogo from '../../resources/icons/facebook.svg';
import instagramLogo from '../../resources/icons/instagram.svg';
import ballLogo from '../../resources/icons/ball.svg';
import beLogo from '../../resources/icons/be.svg';
import mediumLogo from '../../resources/icons/medium.svg';

const Contact = (props) => {
  const { history: { location: {pathname} } } = props;
  const getTitle = () => {
    if(pathname.includes("careers")) return text.contact.title2;
    switch (pathname) {
      case "/projects/burn": return text.contact.title3;
      case "/projects/shrink": return text.contact.title4;
    
      default: return text.contact.title;
    }
  };
  const labels = pathname === "/" ? (
    <Col className="p-0 contact-col-2">
      <div><a target="_blank" href="https://www.goodfirms.co/company/perpetio"><img src="https://goodfirms.s3.amazonaws.com/badges/blue-button/app-development.svg" alt="GoodFirms Badge"/></a></div>
      <div><a target="_blank" href="https://www.goodfirms.co/company/perpetio"><img src="https://goodfirms.s3.amazonaws.com/badges/blue-button/mobile-app-design-companies.svg" alt="GoodFirms Badge"/></a></div>
    </Col>
  ) : null;
  return  (
    <Container className="contact-wrapper" fluid>
      <Row className="contact-margin justify-content-center justify-content-sm-between">
        <Col className="p-0 contact-col-1" xs={9} sm={8} lg={9}>
          <h2 className="contact-title">{getTitle()}</h2>
          <Form display={false} />
        </Col>
        {/*{labels}*/}
        <Col className="contact-column-navbar pl-0">
          <ul className="contact-socials-list">
            {/*<li><a href="https://www.facebook.com/perpetio" target="_blank" rel="noopener noreferrer"><img src={facebookLogo} alt="facebook-logo" /></a></li>*/}
            {/*<li><a href="https://www.instagram.com/perpetio" target="_blank" rel="noopener noreferrer"><img src={instagramLogo} alt="instagram-logo" /></a></li>*/}
            {/*<li><a href="https://dribbble.com/perpetio" target="_blank" rel="noopener noreferrer"><img src={ballLogo} alt="ball-logo" /></a></li>*/}
            {/*<li><a href="https://www.behance.net/perpetio_design" target="_blank" rel="noopener noreferrer"><img src={beLogo} alt="be-logo" /></a></li>*/}
            {/*<li><a href="https://medium.com/perpetio" target="_blank" rel="noopener noreferrer"><img src={mediumLogo} alt="medium-logo" /></a></li>*/}
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Contact);