import React, { useContext, useEffect, useState, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Image } from '../../../Image/Image';
import mainBg from '../../../../resources/images/services_page/ios/main/services-item-main-bg.svg';
import mMainBg from '../../../../resources/images/services_page/ios/main/mobile-services-item-main-bg.svg';
import ActiveContext from '../../../../helpers/activeContext';
import './Main.scss';

export default ({text}) => {
  const active = useContext(ActiveContext);
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  });

  const content = matches ? (
    <Fragment>
      <Col xs={7}>
        <h2 className="services-item-title mb-5" dangerouslySetInnerHTML={{__html: text.title}} />
      </Col>
      <Col xs={5} align="right">
        <Image className="services-item-img" src={`services/${active}/main/${text.image}`} />
      </Col>
      <Col xs={12}>
        <p className="services-item-text">{text.text}</p>
      </Col>
    </Fragment>
  ) : (
    <Fragment>
      <Col sm={6}>
        <div className="services-item-main-content">
          <h2 className="services-item-title mb-sm-3 mb-md-5" dangerouslySetInnerHTML={{__html: text.title}} />
          <p className="services-item-text">{text.text}</p>
        </div>
      </Col>
      <Col sm={6} align="right">
        <Image className="services-item-img" src={`services/${active}/main/${text.image}`} />
      </Col>
    </Fragment>
  );
  
  return (
    <Container fluid className="services-item-main-container">
      <img className="services-item-main-bg" src={matches ? mMainBg : mainBg} alt="services-bg" />
      <div className="services-item-main">
        <ul className="services-item-main-nav">
          {
            text.crumbs.map((item, index) => (
              <li key={index}><Link to={item.link}>{item.name}</Link></li>
            ))
          }
        </ul>
        <Row className="justify-content-between mt-3 mt-sm-5">{content}</Row>
      </div>
    </Container>
  );
}
