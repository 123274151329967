export const checkEmailPattern = mail => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(mail);
}

export const checkNamePattern = name => {
  const regex = /^[^0-9]+$/;
  return regex.test(name);
}

export const checkLength = string => {
  if (string.length >= 1 && string.length <= 500) return true;
}

export const uploadCV = (eventFromInputFile) => {
  if(eventFromInputFile.length || eventFromInputFile.target) {
    const [file] = eventFromInputFile.length ? eventFromInputFile : eventFromInputFile.target.files;
    const fileName = file && file.name;
    const fileExtension = fileName && fileName.substring((fileName.lastIndexOf('.')) + 1);
    const fileSize = file && file.size > 0 && file.size/1024/1024;
    if (fileExtension === 'pdf' && fileSize < 10) return file;
    else return null;
  }
};

export const isRetina = (first, second, third, fourth) => {
  if (window.devicePixelRatio < 2) {
    return first
  } else if (window.devicePixelRatio < 3) {
    return second;
  } else if (window.devicePixelRatio < 4) {
    return third;
  } else {
    return fourth;
  }
};
