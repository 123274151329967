import React, { useState, useEffect } from 'react';
import text from '../../../../translation.json';
import { Container, Row, Col } from 'reactstrap';
import mainBg1 from '../../../../resources/images/services_page/services-bg-1.svg';
import mainMBg1 from '../../../../resources/images/services_page/services-mobile-bg-1.svg';
import './servicesIndustries.scss';

export default () => {
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  }, []);
  return (
    <Container className="services-industries" fluid>
      <img className="services-main-bg-1" src={matches ? mainMBg1 : mainBg1 } alt="services-background" />
      <Row className="services-industries-row">
        <Col sm={9} md={8} lg={6} className="p-0" align="center">
          <h2 className="services-industries-title mb-3 mb-sm-4">{text.services.industries.title}</h2>
          <p className="services-industries-text">{text.services.industries.text}</p>
        </Col>
      </Row>
      <Row className="services-industries-row-2">
        {
          text.services.industries.blocks.map((block, index) => (
            <Col className="services-industries-block" xs={6} sm={4} key={index}>
              <img src={require(`../../../../resources/images/services_page/${block.image}`)}></img>
              <p className="services-industries-block-description mt-4 mb-5">{block.text}</p>
            </Col>
          ))
        }
      </Row>
    </Container>
  );
};
