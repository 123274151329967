import React from 'react';

export default ({active}) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.8334 15H4.16675C3.70758 15 3.33341 14.6267 3.33341 14.1667V6.04171L9.50008 10.6667C9.64841 10.7784 9.82425 10.8334 10.0001 10.8334C10.1759 10.8334 10.3517 10.7784 10.5001 10.6667L16.6667 6.04171V14.1667C16.6667 14.6267 16.2926 15 15.8334 15ZM15.2776 5.00004L10.0001 8.95837L4.72261 5.00004H15.2776ZM15.8334 3.33337H4.16675C2.78841 3.33337 1.66675 4.45504 1.66675 5.83337V14.1667C1.66675 15.545 2.78841 16.6667 4.16675 16.6667H15.8334C17.2117 16.6667 18.3334 15.545 18.3334 14.1667V5.83337C18.3334 4.45504 17.2117 3.33337 15.8334 3.33337Z" fill={active ? "#0085FF" : "#B9C1CD"} />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="3" width="18" height="14">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.8334 15H4.16675C3.70758 15 3.33341 14.6267 3.33341 14.1667V6.04171L9.50008 10.6667C9.64841 10.7784 9.82425 10.8334 10.0001 10.8334C10.1759 10.8334 10.3517 10.7784 10.5001 10.6667L16.6667 6.04171V14.1667C16.6667 14.6267 16.2926 15 15.8334 15ZM15.2776 5.00004L10.0001 8.95837L4.72261 5.00004H15.2776ZM15.8334 3.33337H4.16675C2.78841 3.33337 1.66675 4.45504 1.66675 5.83337V14.1667C1.66675 15.545 2.78841 16.6667 4.16675 16.6667H15.8334C17.2117 16.6667 18.3334 15.545 18.3334 14.1667V5.83337C18.3334 4.45504 17.2117 3.33337 15.8334 3.33337Z" fill="white"/>
    </mask>
  </svg>
);
