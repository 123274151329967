import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import LazyLoad from 'react-lazyload';
import { Image } from '../../../Image/Image';
import './productOnboarding.scss';

export default ({text}) => {
  const mediaMatch = window.matchMedia('(max-width: 700px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  });

  return (
    <Container className="meest-onboarding-wrapper" fluid>
      <Row className="meest-onboarding-row">
        <Col xs={12}>
          <h2 className="meest-onboarding-title">{text.title}</h2>
          <p className="meest-onboarding-text" dangerouslySetInnerHTML={{__html: matches ? text.raw_text : text.text}} />
        </Col>
        {
          text.screens.map((screen, index) => (
            <Col xs={4} className="meest-onboarding-col" key={index}>
              <LazyLoad once offset={1500}>
                <Image src={`meest/onboarding/${screen}`} />
              </LazyLoad>
            </Col>
          ))
        }
      </Row>
    </Container>
  );
  }