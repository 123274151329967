import React from 'react';
import { Row, Container } from 'reactstrap';
import './positions.scss';
import { withRouter } from 'react-router-dom';
const Positions = ({ text, history, block }) => {
  const goToPosition = (link) => history.push(`/careers/${link}`);
  return (
    <Container fluid className="positions">
      {
        block ? <h3 className="block-title mb-5 mx-3 mx-sm-0">{text.title}</h3> : <h1 className="positions-title">{text.title}</h1> 
      }
      <div className={block ? "block-row" : "positions-row"}>
        {
          text.items.map((position, index) => (
            <div
              onClick={() => goToPosition(position.link)}
              className="position-block"
              key={index}>
              <h3 className="position-title">{position.title}</h3>
              <p className="position-count">{position.count}</p>
              <Row className="m-0 mt-auto">
                {
                  position.description.map((item, index) => (
                    <div key={index} className="position-description">
                      <img className="mr-2" src={require(`../../../resources/icons/${item.icon}`)} alt="icon" />
                      <p className="m-0">{item.text}</p>
                    </div>
                  ))
                }
              </Row>
            </div>
          ))
        }
      </div>
    </Container>
  );
}

export default withRouter(Positions);

