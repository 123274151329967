import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import LazyLoad from 'react-lazyload';
import bg from '../../../../resources/images/commit/commit-flow-bg.png'
import './commitFlow.scss';

export default ({text}) => (
  <Container className="commit-flow" fluid>
    <h2 className="commit-flow-title">{text.title}</h2>
    <LazyLoad once offset={1000}>
      <img src={bg} className="commit-flow-img" />
    </LazyLoad>
  </Container>
);
