import React, { Fragment } from 'react';
import ProductMain from '../../components/Projects/Shrink/ProductMain/ProductMain';
import ProductOverview from '../../components/Projects/Shrink/ProductOverview/ProductOverview';
import ProductCollaboration from '../../components/Projects/Shrink/ProductCollaboration/ProductCollaboration';
import ProductFeatureBlock from '../../components/Projects/Shrink/ProductFeatureBlock/ProductFeatureBlock';
import ProductDescription from '../../components/Projects/Shrink/ProductDescription/ProductDescription';
import ProductSlider from '../../components/Projects/Shrink/ProductSlider/ProductSlider';
import ProductResult from '../../components/Projects/Shrink/ProductResult/ProductResult';


export default ({data}) => (
  <Fragment>
    <div className="shrink-wrapper">
      <ProductMain text={data} />
      <ProductOverview text={data} />
      <ProductFeatureBlock text={data} />
      <ProductDescription text={data} />
      <ProductSlider text={data.product_slides} />
      <ProductResult text={data} />
      <ProductCollaboration text={data} />
    </div>
  </Fragment>
);
