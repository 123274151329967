import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ReactCompareImage from '../../../Compare/Compare';
import compareIcon from '../../../../resources/images/compare_icon.svg';
import './compare.scss';

const compare = ({text}) => (
  <Container className="product-compare" fluid>
    <Row className="product-compare-row">
    {
          text.compare.map( (item, i) => (
            <Col key={i} className="product-compare-col px-sm-5" xs={12} sm={6} md={3}>
                <ReactCompareImage 
                    sliderLineWidth={0} 
                    animate={true}
                    leftImage={`/burn/compare/${item.image_old}`} 
                    leftImageSrcSet={`/burn/compare/${item.image_old.split('.')[0]}@2x.${item.image_old.split('.')[1]} 2x, /burn/compare/${item.image_old.split('.')[0]}@3x.${item.image_old.split('.')[1]} 3x, /burn/compare/${item.image_old.split('.')[0]}@4x.${item.image_old.split('.')[1]} 4x`} 
                    rightImage={`/burn/compare/${item.image_new}`}
                    rightImageSrcSet={`/burn/compare/${item.image_new.split('.')[0]}@2x.${item.image_new.split('.')[1]} 2x, /burn/compare/${item.image_new.split('.')[0]}@3x.${item.image_new.split('.')[1]} 3x, /burn/compare/${item.image_new.split('.')[0]}@4x.${item.image_new.split('.')[1]} 4x`}
                    sliderPositionPercentage={0.5}
                    handle={<img height="50" src={compareIcon} />}
                    animate={true}
                />
            </Col>
          ))
    }
    </Row>
  </Container>
);

export default compare;