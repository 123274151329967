import React, { useState, useEffect } from 'react';
import text from '../../translation.json';
import { Container, Row, Col } from 'reactstrap';
import Mobile from './Mobile';
import './development.scss';

const Development = () => {
  const mediaMatch = window.matchMedia('(max-width: 650px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  });
  return (
    <Container fluid className="development">
      {/*<p className="big-opacity-text">{text.development.big_opacity_text}</p>*/}
      {matches ? <Mobile /> : <Row>
        {
          text.development.columns.map(service => (
            <Col
              key={service.id}
              sm={4}
              className="column">
              <Col
                className="column-layer"
                style={{ backgroundImage: `url(${require("../../resources/images/services/" + service.bg)})` }}>
                <p className="column-def" dangerouslySetInnerHTML={{__html: service.def}} />
              </Col>
              <p className="column-text" dangerouslySetInnerHTML={{__html: service.text}} />
            </Col>
          ))
        }
      </Row>
    }
    </Container>
  );
};

export default Development;