import React, { Fragment } from 'react';
import ServicesMain from '../../components/Services/Main/ServicesMain/ServicesMain';
import ServicesBlocks from '../../components/Services/Main/ServicesBlocks/ServicesBlocks';
import ServicesIndustries from '../../components/Services/Main/ServicesIndustries/ServicesIndustries';
import ServicesExplore from '../../components/Services/Main/ServicesExplore/ServicesExplore';

export default () => (
  <Fragment>
    <ServicesMain />
    <ServicesBlocks />
    <ServicesIndustries />
    <ServicesExplore />
  </Fragment>
);
