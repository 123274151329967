import React, { useState } from 'react';
import {
  Button,
  Form,
  Col,
  InputGroup,
  Input,
  InputGroupAddon
} from 'reactstrap';
import { checkEmailPattern, checkNamePattern, checkLength } from '../../../helpers/validationHelpers';
import FormError from '../../FormError/FormError';
import loadingIcon from '../../../resources/icons/loading.svg';
import NameIcon from '../../../resources/icons/name.js';
import EmailIcon from '../../../resources/icons/email.js';
import Upload from '../uploadButton/uploadButton';
import './form.scss';

export default (props) => {
  const [nameFocus, setNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [name , setName] = useState('');
  const [email , setEmail] = useState('');
  const [message , setMessage] = useState('');
  const [file , setFile] = useState(null);
  const [invalid , setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    position,
    handleResponse,
    text,
    handleModal
  } = props;

  const getErrorClass = (condition) => condition ? " error": "";

  const handleSubmit = event => {
    event.preventDefault();
    
    if (checkNamePattern(name) && checkLength(message) && checkEmailPattern(email) && file) {
      setLoading(true);
      const data = new FormData();
      data.append('subject', position);
      data.append('email', email);
      data.append('name', name);
      data.append('message', message.trim());
      const blob = new Blob([file], { type: "application/pdf"});
      data.append('file', blob);
      fetch(`${window.location.protocol}//${window.location.host}/api/v1/send`, {
        method: 'POST',
        body: data
      })
        .then(res => res.json())
        .then(result => {
          setLoading(false);
          const { success } = result;
          if(success) {
            handleResponse(text.success_response);
          }
          else {
            handleResponse(text.bad_response);
          }
          handleModal && handleModal();
        })
        .catch(error => {
          setLoading(false);
          handleResponse(text.bad_response);
          handleModal && handleModal();
        })
    } else {
      event.stopPropagation();
      setInvalid(true);
    }
  };
  return (
    <Form className="contact-form row">
      <Col className="mb-4 p-0" sm={12}>
        <InputGroup className={`contact-form-group${getErrorClass(invalid && !checkNamePattern(name))}`}>
          <InputGroupAddon className="mr-2 align-items-center" addonType="prepend"><NameIcon active={nameFocus} /></InputGroupAddon>
          <Input
            onFocus={() => setNameFocus(true)}
            onBlur={() => setNameFocus(false)}
            invalid={invalid && !checkNamePattern(name)}
            onChange={({ target: { value } }) => setName(value)}
            className="contact-form-input"
            type="name"
            name="name"
            placeholder={text.placeholders.name}
            required />
        </InputGroup>
            {invalid && !checkNamePattern(name) ? <FormError content="name" /> : null}
        </Col>
      <Col className="mb-4 p-0" sm={12}>
        <InputGroup className={`contact-form-group${getErrorClass(invalid && !checkEmailPattern(email))}`}>
          <InputGroupAddon className="mr-2 align-items-center" addonType="prepend"><EmailIcon active={emailFocus} /></InputGroupAddon>
          <Input
            onFocus={() => setEmailFocus(true)}
            onBlur={() => setEmailFocus(false)}
            invalid={invalid && !checkEmailPattern(email)}
            onChange={({ target: { value } }) => setEmail(value)}
            className="contact-form-input"
            type="email"
            name="email"
            placeholder={text.placeholders.email}
            required />
        </InputGroup>
        {invalid && !checkEmailPattern(email) ? <FormError content="email" /> : null}
        </Col>
        <Col className="p-0" sm={12}>
          <InputGroup className={`contact-form-group message${getErrorClass(invalid && !checkLength(message))}`}>
            <Input
              invalid={invalid && !checkLength(message)}
              rows={4}
              onChange={({ target: { value } }) => setMessage(value)}
              className="contact-form-input message"
              type="textarea"
              name="text"
              placeholder={text.placeholders.area} />
          </InputGroup>
          {invalid && !checkLength(message) ? <FormError content="message" /> : null}
        </Col>
        <Col className="p-0 pt-4 pb-4" sm={12}>
          <Upload setFile={setFile} />
          {invalid && !file ? <div className="form-error file-invalid">File is not uploaded</div> : null}
        </Col>
        <Button onClick={handleSubmit} className="submit-btn" color="primary">{loading ? <img className="loading-icon" src={loadingIcon} alt="loading-icon" /> : text.btn}</Button>
    </Form>
  );
};
