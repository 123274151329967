import React, { useContext, Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Image } from '../../../Image/Image';
import ActiveContext from '../../../../helpers/activeContext';
import mainBg1 from '../../../../resources/images/services_page/full-cycle/services-fullcycle-cycle-bg-1.svg';
import mMainBg1 from '../../../../resources/images/services_page/full-cycle/mobile-services-fullcycle-cycle-bg-1.svg';
import mainBg2 from '../../../../resources/images/services_page/full-cycle/services-fullcycle-cycle-bg-2.svg';
import mMainBg2 from '../../../../resources/images/services_page/full-cycle/mobile-services-fullcycle-cycle-bg-2.svg';
import './Cycle.scss';

export default ({text}) => {
  const active = useContext(ActiveContext);
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  const arrowDown = (active) => active ? (
    <div className={matches ? 'mobile-cycle-arrow-down' : 'cycle-arrow-down'}>
      <div className="cycle-arrow-wrapper">
        <span className="arrow-down left"></span>
        <span className="line-down"></span>
        <span className="arrow-down right"></span>
      </div>
    </div>
  ) : null;

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  });
  return (
    <Row className="services-item-cycle">
      <img className="services-item-cycle-bg-1" src={matches ? mMainBg1 : mainBg1} alt="cycle-bg" />
      <img className="services-item-cycle-bg-2" src={matches ? mMainBg2 : mainBg2} alt="cycle-bg" />
      <Col xs={12} align="center">
        <h3 className="services-item-cycle-title pb-sm-5 mb-5">{text.title}</h3>
      </Col>
      <Col xs={11} sm={10}>
        <Row className="services-item-cycle-row">
          {
            text.items.map((cycle, index) => (
              <Fragment key={index}>
                <Col
                  className="services-item-cycle-col"
                  align="center">
                    <Image src={`services/${active}/icons/${cycle.icon}`} alt="project-feature" />
                    <p className="services-item-cycle-text" dangerouslySetInnerHTML={{__html: cycle.text}} />
                    <div className="cycle-arrow">
                      <div className="cycle-arrow-wrapper">
                        <span className="arrow left"></span>
                        <span className="line"></span>
                        <span className="arrow right"></span>
                      </div>
                    </div>
                    {matches ? arrowDown(cycle.mArrow) : arrowDown(cycle.arrow)}
                </Col>
                <p className="breaker"></p>
              </Fragment>
            ))
          }
        </Row>
      </Col>
    </Row>
  );
}
