import React, { Fragment } from 'react';
import Slider from "react-slick";
import text from '../../../../translation.json';
import { Container, Col } from 'reactstrap';
import arrow from '../../../../resources/images/services_page/block-arrow.svg';
import { withRouter } from 'react-router';
import './servicesExplore.scss';

const ServicesExplore = ({history}) => {
  const goToProject = (project) => {
    history.push(`/projects/${project}`);
  }
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    swipeToSlide: true,
    touchThreshold: 50,
    buttons: false,
    dots: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          centerPadding: "30px",
          centerMode: true
        }
      }
    ]
    };
  return (
    <Container className="services-explore" fluid>
      <h2 className="services-explore-title mb-5">{text.services.explore.title}</h2>
      <Slider {...settings}>
          {text.services.explore.slider.map((slide,i) => {
            const logo1x = slide.logo;
            const logo2x = slide.logo.substr(0, slide.logo.length-4) + '@2x' + slide.logo.substr(-4);
            const logo3x = slide.logo.substr(0, slide.logo.length-4) + '@3x' + slide.logo.substr(-4);
            return (
              <Fragment key={i}>
              <Col
                onClick={() => goToProject(slide.link)}
                className="explore-column"
                style={{backgroundImage: `url(${require("../../../../resources/images/portfolio/" + slide.bg)})`}}>
                  <div
                    className="explore-work-wrapper">
                    <div>
                      <img className="explore-work-logo" 
                            src={require(`../../../../resources/images/portfolio/${logo1x}`)} 
                            srcSet={require("../../../../resources/images/portfolio/" + logo1x) + ' 1x,' +
                                    require("../../../../resources/images/portfolio/" + logo2x) + ' 2x,' +
                                    require("../../../../resources/images/portfolio/" + logo3x) + ' 3x'}
                            alt={slide.name} />
                      <div className="explore-work-text" dangerouslySetInnerHTML={{ __html: slide.text }}></div>
                      <ul className="explore-work-types">
                        {slide.text_type.map((item, index) => <li key={index}>{item}</li>)}
                      </ul>
                    </div>
                    <span className="arrow-button">
                      <img src={arrow} alt="right-arrow"></img>
                    </span>
                  </div>
              </Col>
              </Fragment>
          )}
          )
        }
      </Slider>
    </Container>
  );
};


export default withRouter(ServicesExplore);
