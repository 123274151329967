import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import LazyLoad from 'react-lazyload';
import Slider from "react-slick";
import { Image } from '../../../Image/Image';
import './productWireframes.scss';

export default ({text}) => {

  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const mediaMatch1 = window.matchMedia('(max-width: 700px)');
  const [matches1, setMatches1] = useState(mediaMatch1.matches);
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    const handler1 = e => setMatches1(e.matches);
    mediaMatch.addListener(handler);
    mediaMatch1.addListener(handler1);
  });

  const screens = matches ? text.mScreens : text.screens;
  
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    arrows: false,
    verticalSwiping: true,
    autoplay: true,
    swipeToSlide: true,
    touchThreshold: 50,
    buttons: false,
    draggable: true,
    pauseOnHover:false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "60px"
        }
      }
    ]
  };

  return (
    <Container className="meest-wireframes-wrapper" fluid>
      <Row className="meest-wireframes-row">
        <Col xs={12}>
          <h2 className="meest-wireframes-title">{text.title}</h2>
          <p className="meest-wireframes-text" dangerouslySetInnerHTML={{__html: matches1 ? text.raw_text : text.text}} />
        </Col>
        <Col xs={12} className="meest-wireframes-images">
            <Slider {...settings}>
                {screens.map((slide,i) => (
                      <LazyLoad key={i} once offset={1500}>
                        <Image src={`meest/wireframes/${slide}`} />
                      </LazyLoad>
                ))}
            </Slider>
        </Col>
      </Row>
    </Container>
  );
}