import React from 'react';
import text from '../../translation.json';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Container, Row, Navbar, Nav, NavLink, NavItem } from 'reactstrap';
import './footer.scss';

const Footer = () => {
  return (
    <Container fluid className="footer-wrapper">
      <Row sm={10} className="footer-nav justify-content-xs-center">
        <Navbar expand="sm" style={{ width: "100%" }} dark>
            <Nav className="custom-nav justify-content-end" navbar>
              <NavItem><NavLink tag={RRNavLink} to="/privacy-policy" className="docs">{text.menu.policy}</NavLink></NavItem>
            </Nav>
        </Navbar>
      </Row>
    </Container>
  );
};

export default Footer;