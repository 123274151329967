import React, { useState, useEffect } from 'react';
import ProductMain from '../../components/Projects/Meest/ProductMain/ProductMain';
import ProductMainMobile from '../../components/Projects/Meest/ProductMain/Mobile/Mobile';
import ProductOverview from '../../components/Projects/Meest/ProductOverview/ProductOverview';
import ProductDescription from '../../components/Projects/Meest/ProductDescription/ProductDescription';
// import ProductCollaboration from '../../components/Projects/Meest/ProductCollaboration/ProductCollaboration';
import ProductFeature from '../../components/Projects/Meest/ProductFeature/ProductFeature';
import ProductWireframes from '../../components/Projects/Meest/ProductWireframes/ProductWireframes';
import ProductOnboarding from '../../components/Projects/Meest/ProductOnboarding/ProductOnboarding';
import ProductPackages from '../../components/Projects/Meest/ProductPackages/ProductPackages';
import ProductScreens from '../../components/Projects/Meest/ProductScreens/ProductScreens';
import ProductShipment from '../../components/Projects/Meest/ProductShipment/ProductShipment';
import ProductSlider from '../../components/Projects/Meest/ProductSlider/ProductSlider';

export default ({data}) => {
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  }, []);
  return (
    <div className="meest-wrapper">
      { matches ? <ProductMainMobile text={data} />  : <ProductMain text={data} /> }
      <ProductOverview text={data} />
      <ProductDescription text={data} />
      <ProductFeature text={data} />
      <ProductWireframes text={data.product_wireframes} />
      { matches ? <ProductSlider text={data.product_onboarding} mode="onboarding" /> : <ProductOnboarding text={data.product_onboarding} /> }
      { matches ? <ProductSlider text={data.product_packages} /> : <ProductPackages text={data.product_packages} /> }
      { matches ? <ProductSlider text={data.product_shipment} mode="shipments"  /> : <ProductShipment text={data.product_shipment} /> }
      <ProductScreens text={data.product_screens} />
      {/* <ProductCollaboration text={data} /> */}
    </div>
  );
}
