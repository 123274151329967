import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import { Container, Row, Col } from 'reactstrap';
import './productDescription.scss';
import ProductLayout from '../../../../components/Projects/Shrink/ProductLayout/ProductLayout';

const ProductDescription = ({text}) => {

  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  });

  return (
    <Container className="product-description" fluid>
      <Row className="my-5 margin justify-content-center">
        <Col className="product-description-row1 p-0" md={10} xl={7}>
          <h3 className="title mb-2 mb-md-4">{text.product_description.column1.title}</h3>
          <p className="description">{text.product_description.column1.text}</p>
        </Col>
      </Row>
   
        <Container className="wireframes" fluid>
            <Row className="mb-5 margin justify-content-center">
              <Col className="product-description-row2 p-0" md={10} xl={7}>
                <h3 className="title mb-2 mb-md-4">{text.product_description.column4.title}</h3>
                <p className="description" dangerouslySetInnerHTML={{__html: text.product_description.column4.text}} />
              </Col>
            </Row>
            {
      !matches ? (
            <Row className="margin screens justify-content-center">
              {
                text.product_description.column4.screens.map((screen, index) => (
                  <Col key={index} className="product-screens-col" align="center" xs={10} md={4}>
                    <LazyLoad once offset={1000}>
                      <img
                        src={require(`../../../../resources/images/shrink/wireframes/${screen.wireframe2x}`)}
                        srcSet={require(`../../../../resources/images/shrink/wireframes/${screen.wireframe2x}`) + ' 2x,' +
                                require(`../../../../resources/images/shrink/wireframes/${screen.wireframe4x}`) + ' 3x'}
                        alt="product-wireframe-1" />
                    </LazyLoad>
                  </Col>
                ))
              }
            </Row>
            ) : <ProductLayout text={text} /> }
          </Container>
        
      
    </Container>
  );
  }

export default ProductDescription;