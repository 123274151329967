import React from 'react';

export const Image = (image) => {
    const imageName = image.src.split('.')[0];
    const imageExt = image.src.split('.')[1];
    const image1x = `/${imageName}.${imageExt}`;
    const image2x = `/${imageName}@2x.${imageExt}`;
    const image3x = `/${imageName}@3x.${imageExt}`;
    const image4x = `/${imageName}@4x.${imageExt}`;
    return (
        <img style={image.style} className={image.className} srcSet={`${image1x} 1x, ${image2x} 2x, ${image3x} 3x, ${image4x} 4x,`} alt={image.alt || "screen"} />
    );
}
