import React from 'react';
import text from '../../translation.json';
import { Container, Row, Col } from 'reactstrap';
import './analytics.scss';

const Development = () => {
  return (
    <Container fluid className="analytics">
      <p className="big-opacity-text">{text.analytics.big_opacity_text}</p>
      <Row>
        <Col xs={6} lg={3} className="column border-top-0">
          <h2 className="column-header">{text.analytics.column1.count}</h2>
          <p className="column-text" dangerouslySetInnerHTML={{__html: text.analytics.column1.text}} />
        </Col>
        <Col xs={6} lg={3} className="column border-top-0">
          <h2 className="column-header">{text.analytics.column2.count}</h2>
          <p className="column-text" dangerouslySetInnerHTML={{__html: text.analytics.column2.text}} />
        </Col>
        <Col xs={6} lg={3} className="column border-bottom-0">
          <h2 className="column-header">{text.analytics.column3.count}</h2>
          <p className="column-text" dangerouslySetInnerHTML={{__html: text.analytics.column3.text}} />
        </Col>
        <Col xs={6} lg={3} className="column border-bottom-0">
          <h2 className="column-header">{text.analytics.column4.count}</h2>
          <p className="column-text" dangerouslySetInnerHTML={{__html: text.analytics.column4.text}} />
        </Col>
      </Row>
    </Container>
  );
};

export default Development;