import React, { useState, useEffect } from 'react';
import TextTransition from "react-text-transition";
import text from '../../translation.json';
import { Container, Row, Col } from 'reactstrap';
import './main.scss';

const Main = () => {
  const [index, setIndex] = useState(0);
  
  useEffect(() => {
    const intervalId = setInterval(() => setIndex(index => index + 1), 3000);
    return function clean() {
      clearInterval(intervalId);
    }
  }, []);

  return (
    <Container className="main" fluid>
      <Row className="my-5 margin">
        <Col className="col-12 col-sm-8 col-lg-7 p-0">
          <h1 className="title mb-5">
            <TextTransition
              direction="down"
              text={ text.main.changing[index % text.main.changing.length] } />
            <div>dev.</div>
            <div>Studio</div>
          </h1>
        </Col>
        <Col className="col-12 col-md-4 position">
          <p className="description" dangerouslySetInnerHTML={{__html: text.main.text}} />
        </Col>
      </Row>
    </Container>
  );
}

export default Main;