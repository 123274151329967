import React, { useState, useEffect } from 'react';
import './productLayout.scss';

export default ({text}) => {
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  }, []);
  return matches ? <div
                      style={{backgroundImage: `url(${require("../../../../resources/images/" + text.product_layout)})`}}
                      className="product-layout"/> : null;
};