import React from 'react';
import CareersMain from '../../components/Careers/CareersMain/CareersMain';
import Positions from '../../components/Careers/Positions/Positions';
import CareersInfo from '../../components/Careers/CareersInfo/CareersInfo';
import Serapator from '../../components/Serapator/Serapator';
import data from '../../translation.json';

const careers = () => (
  <div className="careers">
    <CareersMain text={data.careers.main} />
    <Positions text={data.careers.items} />
    <CareersInfo text={data.careers.info} />
    <Serapator />
  </div>
);

export default careers;