import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import text from '../../translation';
import { Container, Row, Col } from 'reactstrap';
import LazyLoad from 'react-lazyload';
import Lightbox from 'react-image-lightbox';
import AOS from 'aos';
import Parallax from '../Parallax/Parallax';
import 'aos/dist/aos.css';
import 'react-image-lightbox/style.css';
import './products.scss';

const Products = (props) => {
  const [image, setImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const mediaMatch1 = window.matchMedia('(max-width: 991px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const [matches1, setMatches1] = useState(mediaMatch1.matches);
  
  const goToProject = (project) => {
    console.log(project);
    if(parseInt(project)) {
      const newImage = text.works.items.find(img => img.id === project);
      setImage(newImage);
      setIsOpen(true);
    } else {
      props.history.push(`/projects/${project}`);
    }
  }

  useEffect(() => {
    isOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
  }, [isOpen]);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    const handler1 = e => setMatches1(e.matches);
    mediaMatch.addListener(handler);
    mediaMatch1.addListener(handler1);
  });
  
  AOS.init();

  return (
    <Container fluid className="products">
      <p className="big-opacity-text-top">{text.products.big_opacity_text_top}</p>
      <p className="big-opacity-text-bottom">{text.products.big_opacity_text_bottom}</p>
      <Row>
        <Col sm={12} className="mb-5" align="center">
          <h2 className="products-header mb-5">{text.products.header}</h2>
        </Col>
      </Row>
      {
        text.products.items.map((product, index) => (
          <Row
            key={index}
            className="product"
            data-aos="zoom-in-up"
            data-aos-offset="150"
            data-aos-duration="800"
            data-aos-easing="ease-in-cubic"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom">
            <LazyLoad once offset={1000}>
              <img className={`product-bg ${product.bg_type}`} src={require(`../../resources/images/${matches ? product.m_bg : product.bg}`)} alt="product-background" />
            </LazyLoad>
            <Col md={12} lg={6} className="products-product-col">
              <Row className="justify-content-center justify-content-lg-start">
                {
                  matches1 ? (
                    <LazyLoad once offset={1000}>
                      <img className="logo mb-4" src={require(`../../resources/images/${matches ? product.m_logo : product.logo}`)} alt="product-logo" />
                    </LazyLoad>
                  ) : (
                    <Parallax>
                      <LazyLoad once offset={1000}>
                        <img className="logo mb-4" src={require(`../../resources/images/${matches ? product.m_logo : product.logo}`)} alt="product-logo" />
                      </LazyLoad>
                    </Parallax>
                  )
                }
              </Row>
              <Row className="justify-content-center justify-content-lg-start">
                <p className="product-text">{product.text}</p>
              </Row>
                  <div
                    data-aos="slide-up"
                    data-aos-offset="150"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-cubic"
                    data-aos-mirror="false"
                    data-aos-once="true"
                    data-aos-anchor-placement="top-bottom">
                    <Row className="features my-3 mt-sm-3 mb-sm-5 justify-content-center justify-content-lg-start">
                      {
                        product.services.map((service, index) => (
                          <Row
                            className="product-feature-row justify-content-center flex-column"
                            align="center"
                            key={index}>
                            <Col className="product-feature-col-1">
                              <img
                                className="product-feature"
                                src={require(`../../resources/images/product-services/${service.image}`)}
                                alt="feature" />
                            </Col>
                            <Col className="product-feature-col-2">
                              <p>{service.text}</p>
                            </Col>
                          </Row>
                        ))
                      }
                    </Row>
              {
                !matches1 ? (
                    <Row className="justify-content-center justify-content-lg-start">
                      <button onClick={() => goToProject(product.link)} className="show-more" type="button">
                        <span className="product-more-info">
                          {text.products.view_more}
                        </span>
                      </button>
                    </Row>
                    ) : null
              }
                  </div>
            </Col>
            <Col md={12} lg={6} className="products-product-img">
              <img className={`phone ${product.frame_type}`} src={require(`../../resources/images/${matches ? product.m_photo : product.frame}`)} alt="product" />
              
            {
              !matches ? (
              <LazyLoad once offset={1500}>
                <video className={`video ${product.frame_type}`} poster={require(`../../resources/images/${product.poster}`)} autoPlay loop muted>
                  <source src={require(`../../resources/videos/${matches ? product.m_gif : product.gif}`)} type="video/mp4" />
                </video>
              </LazyLoad>
              ) : null
            }
            </Col>
            {
              matches1 ? (
                <Col sm={12} align="center">
                  <button onClick={() => goToProject(product.link)} className="show-more" type="button">
                    {text.products.view_more}
                  </button>
                </Col>
              ) : null
            }
          </Row>
        )
      )}
      {image && isOpen && (
        <Lightbox
          enableZoom={true}
          mainSrc={require(`../../resources/images/portfolio/${image.details}`)}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </Container>
  );
};

export default withRouter(Products);