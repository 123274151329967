import React, { useState, useEffect, useContext } from 'react';
import Slider from "react-slick";
import { Container, Row, Col } from 'reactstrap';
import text from '../../../../translation.json';
import arrow from '../../../../resources/images/services_page/block-arrow.svg';
import blockBg from '../../../../resources/images/services_page/services-bg-2.svg';
import { withRouter } from 'react-router-dom';
import ActiveContext from '../../../../helpers/activeContext';
import './servicesBlocks.scss';

const ServicesBlocks = (props) => {
  const mediaMatch = window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const active = useContext(ActiveContext);

  const settings = {
    centerPadding: "30px",
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    swipeToSlide: true,
    touchThreshold: 50,
    buttons: false,
    dots: true,
    draggable: true,
    };

  const setBgStyles = (image) => {
    return matches ? null : {
      backgroundImage: `url(${require("../../../../resources/images/services_page/blocks/" + image)})`
    };
  };

  const blocks = (block, index) => (
    <Col
      onClick={() => goToService(block.link)}
      style={setBgStyles(block.bg)}
      className={`services-blocks-block ${block.link === active ? "active" : ""} p-0 mb-4 mb-sm-0`}
      sm={6}
      md={4}
      key={index}>
        <div className="services-block-wrapper">
          <div className="services-block-content">
            <h3 className="services-block-title mb-3" dangerouslySetInnerHTML={{__html: block.title}}></h3>
            <p className="services-block-text">{block.text}</p>
          </div>
          {
            block.link === active ? null : (
              <span className="arrow-button">
                <img src={arrow} alt="right-arrow"></img>
              </span>
            )
          }
        </div>
    </Col>
  );
  
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
  }, []);
    
  const goToService = (service) => service !== active && props.history.push(`/services/${service}`);
  
  return (
    <Container className="services-blocks" fluid>
      {
        matches ? null : <img className="services-block-bg" src={blockBg} alt="services-background" />
      }
      {
        active ? <h2 className="services-blocks-title mb-5">{text.services.ios.discover_title}</h2> : null
      }
      {
        active && matches ? <Slider {...settings}>{
          text.services.blocks.filter(block => block.link !== active).map((block, index) => blocks(block, index))
        }</Slider> : <Row className="services-blocks-row">{
          text.services.blocks.map((block, index) => blocks(block, index))
        }</Row>
      }
    </Container>
  );
}

export default withRouter(ServicesBlocks);
