import React from 'react';
import './parallax.scss';

export default ({ children }) => (
  <div className="parallax">
    <div className="parallax-top-left"></div>
    <div className="parallax-top-right"></div>
    <div className="parallax-bottom-left"></div>
    <div className="parallax-bottom-right"></div>
    <div className="parallax-content">
      {children}
    </div>
  </div>
);