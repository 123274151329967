import React, { useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Home from './containers/Home/Home';
import Wrapper from './hoc/Wrapper/Wrapper';
import ScrollToTop from './helpers/scrollToTop';
import Works from './containers/Works/Works';
import Services from './containers/Services/Services';
import Ios from './containers/Services/Ios/Ios';
import Android from './containers/Services/Android/Android';
import GameArt from './containers/Services/GameArt/GameArt';
import GameDev from './containers/Services/GameDev/GameDev';
import UiUx from './containers/Services/UiUx/UiUx';
import FullCycle from './containers/Services/FullCycle/FullCycle';
import Careers from './containers/Careers/Careers';
import Career from './containers/Careers/Career/Career';
import PrivacyPolicy from './containers/PrivacyPolicy/PrivacyPolicy';
import ContactUs from './containers/ContactUs/ContactUs';
import Burn from './containers/Burn/Burn';
import Commit from './containers/Commit/Commit';
import Shrink from './containers/Shrink/Shrink';
import Census from './containers/Census/Census';
import Meest from './containers/Meest/Meest';
import Aob from './containers/Aob/Aob';
import Header from './components/Header/Header';
import text from './translation';
import './App.scss';

function App() {
  const [open, setOpen] = useState(false);
  
  const checkEventTarget = (event) => {
    if(open) {
      const tag = event.target.tagName.toLowerCase();
      !(tag === "ul" || tag === "a") && setOpen(false)
    }
  }
  return (
    <BrowserRouter>
      <Header open={open} setOpen={setOpen} />
      <div onClick={checkEventTarget} className="App">
          <Wrapper>
            <ScrollToTop />
            <Switch>
              <Route path='/' exact component={Home} />
              <Route exact path='/services' component={Services} />
              <Route path='/services/ios' component={() => <Ios data={text.services.ios} />} />
              <Route path='/services/android' component={() => <Android data={text.services.android} />} />
              <Route path='/services/game-art' component={() => <GameArt data={text.services.gameart} />} />
              <Route path='/services/game-dev' component={() => <GameDev data={text.services.gamedev} />} />
              <Route path='/services/ui-ux' component={() => <UiUx data={text.services.uiux} />} />
              <Route path='/services/full-cycle' component={() => <FullCycle data={text.services.fullcycle} />} />
              <Route path='/works' component={Works} />
              <Route path='/privacy-policy' component={PrivacyPolicy} />
              <Route path='/contact' component={ContactUs} />
              <Route path='/projects/burn' component={() => <Burn data={text.applications[0]} />} />
              <Route path='/projects/commit' component={() => <Commit data={text.applications[1]} />} />
              <Route path='/projects/shrink' component={() => <Shrink data={text.applications[2]} />} />
              {/* <Route path='/projects/census' component={() => <Census data={text.applications[3]} />} /> */}
              <Route path='/projects/meest' component={() => <Meest data={text.applications[4]} />} />
              {/* <Route path='/projects/aob' component={() => <Aob data={text.applications[5]} />} /> */}
              <Route exact path='/careers' component={Careers} />
              <Route path='/careers/:career' component={Career} />
              <Redirect to='/' />
            </Switch>
          </Wrapper>
      </div>
    </BrowserRouter>
  );
}

export default App;
