import React, { useEffect, useState } from 'react';
import { NavLink as RRNavLink, withRouter } from 'react-router-dom';
import Logo from './Logo';
import text from '../../translation.json';
import {
  Container,
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavbarBrand,
  NavLink,
  NavItem,
} from 'reactstrap';
import './header.scss';

function Header(props) {
  const {open, setOpen, history:{location: {pathname}}} = props;
  const mediaMatch = window.matchMedia('(max-width: 767px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const whiteNav = ["/projects/burn", "/projects/census", "/projects/aob"]
  const getStyleFromPath = (style1, style2) => (matches ? whiteNav.includes(pathname) && !scrollY : whiteNav.includes(pathname) && !(scrollY > 0)) ? style1 : style2;
  const [scrollY, setScrollY] = useState(0);
  // TOO MUCH LOGIC NEED OPTIMIZATION
  const safariFixedNavStyles = {
    zIndex: 9999,
    position: 'absolute',
    top: 0,
    left: 0
  }
  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
  }, [scrollY]);
  
  useEffect(() => {
    open ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
  }, [open]);
  
  return (
    <Container fluid className="header">
      <Navbar className={`main-navbar${(matches ? scrollY : scrollY > 0) ? " scrolled" : ""}`} expand="md" light={whiteNav.includes(pathname) || !open} dark={(matches ? whiteNav.includes(pathname) && !scrollY : whiteNav.includes(pathname) && !(scrollY > 0)) || open} fixed={!matches ? (scrollY > -1 && "top") : "top"} style={matches ? null : (scrollY < 0 ? safariFixedNavStyles : null)}>
        <NavbarBrand href="/">
          <picture className="d-inline-block align-top">
            <Logo color={getStyleFromPath("white-char", "black-char")} colorB={getStyleFromPath("white-char", "blue-char")} />
          </picture>
        </NavbarBrand>
        <NavbarToggler aria-controls="basic-navbar-nav" onClick={() => setOpen(!open)} />
        <Collapse id="basic-navbar-nav" isOpen={open} navbar>
          <Nav className="ml-auto custom-nav" navbar>
            <NavItem onClick={open ? () => setOpen(!open): null}><NavLink className={getStyleFromPath("white", "black")} tag={RRNavLink} to="/" exact activeClassName='active'>{text.menu.item1}</NavLink></NavItem>
            <NavItem onClick={open ? () => setOpen(!open): null}><NavLink className={getStyleFromPath("white", "black")} tag={RRNavLink} to="/services" exact activeClassName='active'>{text.menu.item2}</NavLink></NavItem>
            <NavItem onClick={open ? () => setOpen(!open): null}><NavLink className={getStyleFromPath("white", "black")} tag={RRNavLink} to="/works" activeClassName='active'>{text.menu.item3}</NavLink></NavItem>
            {/*<NavItem onClick={open ? () => setOpen(!open): null}><NavLink className={getStyleFromPath("white", "black")} tag={RRNavLink} to="/careers" activeClassName='active'>{text.menu.item4}</NavLink></NavItem>*/}
            {/*<NavItem onClick={open ? () => setOpen(!open): null}><NavLink className={getStyleFromPath("white", "black")} href="https://blog.perpet.io">{text.menu.item5}</NavLink></NavItem>*/}
            {/*<NavItem className="nav-item-special" onClick={open ? () => setOpen(!open): null}><NavLink tag={RRNavLink} className={`special${getStyleFromPath(" special-white", "")}`} to="/contact">{text.menu.item6}</NavLink></NavItem>*/}
          </Nav>
        </Collapse>
      </Navbar>
    </Container>
  );
};

export default withRouter(Header);
